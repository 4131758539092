/* Cheat.js */
export default {
    data() {
        return {
            isDevNavVisible: false,
            keyChain : [],
            cheatPanelHidden: false,
        }
    },
    methods: {
        /* ★★★★★ CHEAT MODES ★★★★★ */
        /* works only if (this.allowCheat) */

        toggleCheatPanel(){
            this.cheatPanelHidden = !this.cheatPanelHidden;
        },
        getAdminFromUrl(){
            console.log("★ ★ ★ ★ ★ ★");
            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('cheat')){
                if(urlParams.get('cheat')==='on'){
                    this.isDevNavVisible = true;
                }
            }
        },

        getAdminFromKeys(e){
            this.keyChain.push(e.keyCode);
            if (this.keyChain.length > 8) {
                this.keyChain.shift();
            }

            if (JSON.stringify(this.keyChain) === JSON.stringify([67,72,69,65,84,78,65,86]) ){
                console.log("★ ★ ★ ★ ★ ★");
                this.isDevNavVisible = !this.isDevNavVisible;
            }
        }
        /* ★★★★★ END CHEAT MODES ★★★★★ */
    },
    mounted(){
    // Cheat by keyboard
    window.addEventListener('keydown', this.getAdminFromKeys);   
    this.getAdminFromUrl();
},
}