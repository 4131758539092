export const nl = {

	errors: {
		emptyField: "Je moet een antwoord geven",
		fieldMissingValue: "Een of meer velden hebben een onjuiste of lege waarde",
		dateInvalid: "De datum is niet geldig",
		dateBefore: "De datum mag geen toekomstige datum zijn",
		dateOutOfPeriod: "De aankoop moet worden gedaan binnen de geldige data voor deze actie",
		wrongcnk : "De CNK code is niet correct",
	},

	maintenance: {
		title: "Onderhoud",
		line1 : "Deze website is momenteel in onderhoud en kan niet worden geraadpleegd. Probeer het later nog eens.",
		line2 : "Ons team doet er alles aan om de site zo snel mogelijk weer toegankelijk te maken.",
		thanks : "Dank u voor uw begrip.",
	},


	pandgSpecial: {
		privacy : "Privacy",
		conditionsPdf: "Actie voorwaarden",
		conditionsGeneral: "Algemene voorwaarden"
	},

	footer: {
		genterms: "Algemene voorwaarden",
	},

	gdpr: {
		title: "Privacy notice",
		client: "P&G Health Belgium BV",
	},

	onetrust: {
		cookie: "Beheer cookies",
	},

	navigation: {
		doSurvey: "Antwoorden op de enquête",
		skipSurvey: "Cashback aanvragen",
		previous: "Vorige pagina",
		next: "Volgende pagina",
		claimCashback: "Mijn Cashback aanvragen",
		signup: "Schrijf je nu in!",
		toCustomerWebsite: "Naar onze website",
		readMoreOnWebsite: "Lees meer",
	},

	breadcrumbSteps: {
		registration: "Inschrijven ",
		store: "Doe uw aankopen",
		form: "Vul het formulier in",
		survey: "Vul een optionele vragenlijst in",
		cashback: "Ontvang uw terugbetaling"
	},

	formHeader: {
		topText: "Voor u…",
		bottomText: `* BIJ AANKOOP VAN EEN OVULATIETEST GEAVANCEERD DIGITAAL CLEARBLUE<sup>®</sup> SAMEN MET EEN OMNIBIONTA<sup>®</sup> PRONATAL 12 WEKEN`,
		macaronText: `terugbetaald!<br>Bij aankoop van<br>Clearblue &<br>Omnibionta`
	},

	pageTitles: {
		maxReached: "Maximum aantal inzendingen bereikt",
		wrongCode: "Verkeerde code",
		alreadySend: "Deelname reeds verstuurd",
		confirmation: "Bevestiging",
		beforeStart: "Deze actie is nog niet beschikbaar.",
		actionEnded: "Deze actie is helaas afgelopen.",
	},

	sectionTitles: {
		inscription: "Schrijf u hier in",
	},

	pageLitteracy: {
		maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",
		wrongCode: `De opgegeven code is verkeerd. U moet de link gebruiken die u door CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup> wordt toegestuurd. Maar misschien wil je je inschrijven voor de actie?`,
		alreadySend: "U heeft uw deelname al verzonden",
		registration : {
			byRegistering: "Door me in te schrijven voor deze actie, ga ik akkoord met de",
			privacyNotice: "Privacy notice",
			claimMail : "Inschrijven via e-mail",
			claimWhatsapp: "Inschrijven via Whatsapp",
			claimSms: "Inschrijven via SMS",
			or: "Of",
			whatsappTitle: "Klik op de Whatsapp knop",
			whatsappInfo: "Een venster wordt geopend en u wordt gevraagd om het woord Clearblue-NL van uit uw Whatsapp te verzenden.",
		},
		confirmInscription: {
			thanks: "Bedankt voor uw inschrijving!",
			smsLine1 :"U zal spoedig een SMS ontvangen met alle details.",
			smsLine2: "Ga eens kijken!",
			whatsappLine1: "Controleer uw telefoon.",
			whatsappLine2: "Alle instructies zijn verstuurd via Whatsapp",
			mailLine1: "U zal spoedig een e-mail ontvangen met alle details.",
			mailLine2: "Ga eens kijken!",
		},
		form1: {
			mainTitle: "Ontvang uw cashback nu!",
			aboutYou: "Vertel ons meer over u",
			aboutProduct: "Over ons product",
			popTitle : "Aankoopbewijs",
			aboutYourAnimal : "Over uw hond",
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Neem deel aan onze enquête en krijg een e-coupon van €1.5 voor uw volgende aankoop",
		},
		survey: {
			title: '',
			about: "Enquête",
		},
		confirmation: {
			title: "Bevestiging",
			textes: {
				p1: `Wij danken u voor uw deelname aan de CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup> actie.`,
				p2: "U ontvangt uw terugbetaling binnen de 6 weken, mits u aan de algemene voorwaarden heeft voldaan.",
				coupon: "We hebben je kortingsbon naar het opgegeven e-mailadres verzonden. Geniet ervan!",
				p3: "Met vriendelijke groeten,",
				p4: `Het CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup> team`
			},
		},
		actionEnded: {
			text: "Bezoek onze website regelmatig om op de hoogte te blijven van toekomstige acties."
		},
		beforeStart: {
			text: "U krijgt toegang vanaf "
		},
	},






	inputLabel: {
		defaultListHelper: "Kies er één uit de lijst",
		mandatoryFields: "Verplichte velden",

		optin : {
			label : "Opt-in e-mailmarketing.",
			helper : "",
			complement: `U wilt graag e-mails, aanbiedingen en coupons ontvangen van Clearblue<sup>®</sup> & Omnibionta<sup>®</sup> en <a target="_blank" href="https://www.pg.com/brands">andere vertrouwde</a> P&G-merken.`
		},

		conditions : {
			part1 : "Ik aanvaard",
			link: "de algemene voorwaarden",
			part3: ""
		},

		title : {
			label : "Aanspreking",
			placeholder : "Hoe kunnen we u noemen?",
			helper : "",
		},
		firstname: {
			label: "Gelieve uw voornaam in te vullen",
			placeholder : "Voornaam",
			helper: "",
		},
		lastname: {
			label: "Gelieve uw naam in te vullen",
			placeholder : "Achternaam",
			helper: "",
		},
		email : {
			label: "Gelieve uw e-mail in te vullen",
			placeholder: "naam{'@'}voorbeeld.be",
			helper: "Uw e-mail moet een {'@'} bevatten"
		},
		phone : {
			label: "Gelieve uw GSM-nummer in te vullen",
			placeholder: "Vb: 0477123456",
			helper: ""
		},
		iban : {
			label: "Gelieve uw IBAN nummer te vullen",
			placeholder: "bv: BE00123000456000",
			helper: "Het nummer moet beginnen met BE of LU"
		},
		product: {
			label: "Welk product heeft u aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: ""
		},
		retailer: {
			label: "Waar heeft u het product aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: ""
		},
		freeRetailer: {
			label: "In welke apotheek hebt u uw CLEARBLUE® & OMNIBIONTA® producten gekocht?",
			placeholder: "…",
			helper: ""
		},
		datePurchase : {
			label: "Wanneer hebt u uw CLEARBLUE® & OMNIBIONTA® producten gekocht?",
			placeholder: "jj/mm/aaaa",
			helper: "",
		},
		dropdown : {
			placeholder: "Kies een antwoord…",
			helper: ""
		},
		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: "Wat is de CNK code van het gekochte Clearblue product?",
			placeholder: "0000-000",
			helper: "Het format is: XXXX-XXX",
			error: "De CNK code is niet correct",
		},
		cnk2 : {
			label: "Wat is de CNK code van het gekochte Omnibionta Pronatal product?",
			placeholder: "0000-000",
			helper: "Het format is: XXXX-XXX",
			error: "De CNK code is niet correct",
		},


      //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation

/*
      typePassword : "Gelieve je wachtwoord in te vullen",
      helpPassword : "Je wachtwoord moet X, Y en Z bevatten",
      typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
      */

      upload: {
      	state: "status",
      	uploadYourFile: "Upload bestand",
      	clickOrDrop: "Klik of sleep je ticket hier.",
      	rules: "Afbeelding moet een bestand zijn van het bestandstype JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
      	remove: "Verwijder",
      	waiting: "Wachten op upload...",
      	uploaded: "Geüpload!",
      	error: "Mislukt",
      	uploadsDone: "Upload succesvol afgerond",
      	onlyImages: "Enkel afbeeldingen worden aanvaard.",
      	maxSize: "Maximale grootte van 20mb",
      	uploadFile: "Upload bestand",
      	generalError: "Er ging iets mis met uw upload, gelieve later opnieuw te proberen"
      }
},
}
