/* Functions.js */

/* I will put here functions that 
- have been simplified, 
- are standalone or almost 
- proved to be useful enough 
- are cumbersome in the main file */

/* Functions that stays in the Form.vue are
- New
- Complicated or could be rewritten
- Are changing from one action to another (survey or not survey, how many pages, specific script…)
*/

import config from "../config";

export default {
	data() {
		return {
			actionFullDates: {},
			oauth: {
				token_type:'',
				access_token:'',
				expires_in:''
			},
			inscriptionMean : '',
			lead : {	},
			idLead : config.idlead,
			config: config,
			currentPage : "inscription",
			keyFromUrl: '',

			isThereMessage: false,
			messageContent: [],
			messageTitle: '',
			messageType: "alert",

			isMailFieldDisabled: false,
			isPhoneFieldDisabled: false,
		}
	},
	computed: {
		actionStartDate : function(){
			return this.getHumanReadableDate(this.actionFullDates.startFullDate);
		},
		actionEndDate : function(){
			return this.getHumanReadableDate(this.actionFullDates.endFullDate);
		},
		isMaxParticipationReached: function(){
			return this.lead.participations_left <= 0;
		},
	},
	methods: {

		/* DISPLAY AVAILABLE PARTICIPATION AND REGISTRATION */
		/* For CONSOLE INFO only */
		displayAvailableParticipations(){
			console.log("--------------------------");
			console.group("Available registrations and participations");
			console.log("There are %c" + this.lead.inscriptions_left + "%c available registrations","font-weight:bold;","font-weight:normal;");
			console.log("There are currently %c" + this.lead.inscriptions_total + "%c registration(s) made","font-weight:bold;","font-weight:normal;");
			console.log("There are currently %c" + this.lead.participations_total + "%c participation(s) received","font-weight:bold;","font-weight:normal;");
			console.log("There are still %c" + this.lead.participations_left + "%c available participations","font-weight:bold;","font-weight:normal;");
			console.groupEnd();
			console.log("--------------------------");
		},

		/* FORMAT DATE SO HUMANS CAN EASILY READ THEM */
		getHumanReadableDate(isoDate){
			let d = isoDate.getDate();
			let m = isoDate.getMonth();
			let y = isoDate.getFullYear();
			let humanReadableStartDate = `${d}/${m+1}/${y}`;
			return humanReadableStartDate;
		},

		/* GET ALL DATES USEFUL FOR THIS ACTIONS */
		/* Returns an object with 3 properties: startFullData, endFullData, currentDate */
		getFullDates(){
			let dates = {};
			/* Start date is right from the LeadManagement */
			dates.startFullDate = new Date(this.lead.participationbegindate);
			/* End date requires a modification */
			let dateFromLead = new Date(this.lead.participationextensiondate);
			/* We add 23h59m59s */
			dateFromLead.setTime(dateFromLead.getTime() + (23*60*60*1000) + (59*60*1000) + (59*1000));
			dates.endFullDate = dateFromLead;
			/* And we need the current date, at the moment the form is loaded */
			dates.currentDate = new Date();
			return dates;
		},

		/* DISPLAY THE DATES IN THE CONSOLE */
		/* For CONSOLE INFO only */
		displayDatesInConsole(){
			console.group("Human-readable dates for this action");
			console.log("Current:", this.getHumanReadableDate(this.actionFullDates.currentDate));
			console.log("Starting:", this.getHumanReadableDate(this.actionFullDates.startFullDate));
			console.log("Ending:", this.getHumanReadableDate(this.actionFullDates.endFullDate) , "(+ 23h59m59s)");			
			console.groupEnd();
			console.log("----------------------");
		},



		/* CHECK CURRENT DATE AND DECIDE ACTION TO TAKE */
		checkIfInDate(){
			if (this.isTooSoon()){ this.userIsThereTooSoon(); }
			else if (this.reparticipation) { this.checkIfTimeIsRightForReparticipation(); }
			else { this.checkIfTimeIsRightForParticipation(); }
		},

		checkIfTimeIsRightForReparticipation () {
			if ( this.isInReparticipationTime() ) { this.userIsReparticipating(); }
			else { this.userIsThereTooLate(); }
		},

		checkIfTimeIsRightForParticipation () {
			if ( this.isInParticipationTime() ) { this.userIsParticipating(); }
			else { this.userIsThereTooLate(); }
		},




		/* CONDITIONS RELATIVE TO ACTION'S DATES */
		isTooSoon () {
			return this.actionFullDates.currentDate.getTime() < this.actionFullDates.startFullDate.getTime();
		},
		isTooLate () {
			return this.actionFullDates.currentDate.getTime() > this.actionFullDates.endFullDate.getTime();
		},
		isInReparticipationTime () {
			return this.actionFullDates.currentDate.getTime() < this.actionFullDates.endFullDate.getTime();
		},
		isInParticipationTime () {
			return this.actionFullDates.currentDate.getTime() > this.actionFullDates.startFullDate.getTime() &&
			this.actionFullDates.currentDate.getTime() < this.actionFullDates.endFullDate.getTime();
		},




		/* ACTIONS TO TAKE FOLLOWIND THE CURRENT DATE */
		/* When user CANNOT (re)participate */
		userIsThereTooSoon () {
			console.log("It's too soon!");
			this.goto('beforeStart'); 
		},
		userIsThereTooLate () {
			console.log("It's too late for a (re)participation!");
			console.log("It's just too late for anything…");
			this.goto('actionEnded'); 
		},
		/* When user CAN (re)participate */
		userIsParticipating () {
			console.log("User is participating");
			this.runParticipationFunctions();
		},
		userIsReparticipating () {
			console.log("User is re-participating");
			this.runParticipationFunctions();
		},

		checkIfAlreadyParticipated () {
			/* check if already participated (status === 30) or if reparticipation var is true */
			if (this.ownerData.status !== "30" || this.reparticipation){
				this.noParticipationOrReparticipation();
			}else{
				this.participationAlreadySent();
			}
		},

		runParticipationFunctions () {
			this.putContactInForm();
			if ( this.isMaxParticipationReached && !this.reparticipation ) { this.goto('maxreached'); } 
			else { 
				this.putDataInFields();
				this.checkIfAlreadyParticipated();
			}
		},

		noParticipationOrReparticipation(){
			console.log("Participation has not been sent yet or this is a reparticipation.");
			console.log((this.reparticipation)?"(This is a reparticipation)":"(This is not a reparticipation)");
			this.goto('form1');
		},

		participationAlreadySent(){
			console.log("Participation already sent and this is not a reparticipation.");
			this.goto('participated');
		},





		/* JUST AFTER I GOT THE KEY FROM THE URL */
		checkKey(){
			if (this.keyFromUrl){
				this.getOwnerData();
			}else{
				if (this.isMaxParticipationReached){
					this.goto('maxreached');
				}else{
					this.goto('inscription');
				}
			}
		},


		/* PUT THE PHONE IN THE FORM */
		setPhone(value) {
			console.log("I set the phone value to: " + value);
			this.fieldsValues.phone1 = value;
		},

		/* PUT THE EMAIL IN THE FORM */
		setMail(value) {
			console.log("I set the mail value to: " + value);
			this.fieldsValues.email = value;
		},

		/* MOVE FROM ONE PAGE TO ANOTHER */
		goto(dest){
			console.log("%cI received '" + dest + "' as a target destination","color:#999;");
			this.logCustomEvent(dest); // for pixel.js

			/* WIP Add a meta to discourage robots to index some pages */
			this.checkForNonIndexablesPagesForThis(dest);
			/* WIP END */

			this.currentPage = dest;
		},

		checkForNonIndexablesPagesForThis(dest){
			let notIndexablePages = ['wrongcode', 'maintenance', 'confirminscription', 'confirmation'];
			if (notIndexablePages.includes(dest)){ this.addRobotsNoIndexMeta(); }
		},

		addRobotsNoIndexMeta(){
			const metaRobots = document.createElement('meta');
			metaRobots.name = 'robots';
			metaRobots.content = 'noindex';
			document.head.appendChild(metaRobots);
		},

		/* OPEN A LOCALISED PDF FILE (ie: "conditions_fr_be.pdf") */
		openPDFFile (name='conditions'){
			let link = config.assetPath + "files/" + name + "_";
			window.open(link + this.$i18n.locale.toLowerCase() +".pdf");
		},

		/* GET AND RETURNS A KEY FOUND IN THE URL */
		getKeyFromUrl(){
			console.log("Looking in the URL for a key");
			let urlParams = new URLSearchParams(window.location.search);
			let key = '';
			if (urlParams.has('key')){
				key = urlParams.get('key');
				console.log("-> Found a key: " + key);
			}else{
				console.log("No key found -> registration.");
			}
			return key;
		},

		/* GETTING THE LEAD, ALL INFO ABOUT THE ACTION */
		getLead(idLead){
			console.log("Getting Lead for " + this.idLead + " and looking for the key");
			this.axios.get(`/leads/${idLead}`)
			.then(res => {
				console.log("I managed to get the lead! ");
				this.whatIfIGetTheLead(res.data);			
			})
			.catch(error => {
				console.error("I cannot get the Lead");
				this.whatIfIDontGetTheLead(error);
			})
			.then(()=>{
			});
		},

		/* UPDATE THE VALIDITY FLAG OF A SPECIFIC FIELD */
		updateValidityOfAField(val){
			console.group("Update validity of field " + val.fieldname);
			console.log(`I'm on page ${this.currentPage} trying to update validity of field ${val.fieldname} with ${val.valid}`);
			this.fieldsValidity[this.currentPage][val.fieldname] = val.valid;
			console.groupEnd();
		},

		/* TOASTER DISPLAY A MESSAGE */
		/* Modes are: info|alert */
		displayMessage(title, message, mode='info') {
			console.log("Displaying message");
			this.isThereMessage = true;
			this.messageTitle = title;
			this.messageContent = message;
			this.messageType = mode;
		},

		/* …OR HIDE IT */
		hideMessage() {
			this.isThereMessage = false;
		},

		setInscriptionMean(mean){
			this.inscriptionMean = mean;
		},




		/* DISPLAY STATE OF A BOOLEAN VARIABLE WITH COLOR! */
		consoleInColor(str, bool){
			/* Receive a "key", a "value" and display the value in green (true) or red (false) */
			let color = (bool)?"#080":"#900";
			console.log(`${str}:%c ${bool}`, `color:${color}`);
		},

		/* "WE NEED A BIGGER CONSOLE.LOG" */
		
		alertDev(message){
			console.log(`%c !!!!!!!! — ${message} — !!!!!!!!`,"font-size:1.5em; color:#e00; line-height:2em;");
		},
		



	}
}
