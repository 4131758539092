import axios from 'axios'
import store from "../store/store";
//import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export default class Axios {
    constructor(config/*, finger*/) {
        /*if (typeof finger === 'undefined') {
            throw new Error('Cannot be called directly')
        }*/

        let baseHeader = {}

        const modifiedConfig = config
        const baseUrl = config.baseUrl

        if (Object.prototype.hasOwnProperty.call(modifiedConfig, 'customHeaders')) {
            baseHeader = {...baseHeader, ...config.customHeaders}
        }

        if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Accept')) {
            baseHeader['Accept'] = 'application/json'
        }

        if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Content-Type')) {
            baseHeader['Content-Type'] = 'application/json'
        }

        let service = axios

        if (config.baseUrl) {
            service.defaults.baseURL = config.baseUrl
        } else {
            service.defaults.baseURL = baseUrl
        }

        if (config.customHeaders) {
            const finalHeader = {...baseHeader, ...config.customHeaders}
            for (const key of Object.keys(finalHeader)) {
                service.defaults.headers.common[key] = finalHeader[key]
            }
        } else {
            for (const key of Object.keys(baseHeader)) {
                service.defaults.headers.common[key] = baseHeader[key]
            }
        }

        service.interceptors.request.use(function (config) {
            // Do something before request is sent
            const baseBody = {
                Participation: {
                    fingerPrint: store.getters.getFingerprint,
                },
                fingerprint: store.getters.getFingerprint,
                lang: store.getters.getLang
            }

            if(
                config.method === 'get' ||
                config.method === 'delete'
            ) {
                config.params = {...config.data, ...baseBody}
            }

            if(
                config.method === 'post' ||
                config.method === 'put' ||
                config.method === 'patch'
            ) {
                if(config.data instanceof FormData) {
                    config.data.append('fingerprint', baseBody.fingerprint)
                    config.data.append('lang', baseBody.lang)
                }else{
                    config.data = { ...config.data, ...baseBody}
                }
            }

            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        return service
    }

/*
 * I dont think this is used anymore
 *
    static async build(config = {}) {
        let fingerprint = await FingerprintJS.load({
            token: 'PZ2B2o9KoKgKPsIAoOjH',
            region: 'eu'
        });

        try{
            fingerprint = await fingerprint.get()

            if(Object.prototype.hasOwnProperty.call(fingerprint, 'visitorId')) {
                fingerprint = fingerprint.visitorId;
            }

        }catch (e) {
            fingerprint = 'nofinger.'+ Date.now() + '.' + (Math.random() + 1).toString(36).substring(7);
        }

        store.commit('SET_FINGERPRINT', fingerprint)

        return new Axios(config, fingerprint);
    }
 */

}
