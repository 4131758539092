export const fr = {

	errors: {
		emptyField: "Vous devez donner une réponse",
		fieldMissingValue: "Un ou plusieurs champs ont une valeur erronée ou vide",
		dateInvalid: "La date n'est pas valide",
		dateBefore: "La date ne peut être une date future",
		dateOutOfPeriod: "L'achat doit se faire dans les dates valides pour cette action",
		wrongcnk : "Le code CNK n'est pas correct",
	},

	maintenance: {
		title: "Maintenance",
		line1 : "Ce site web est actuellement en maintenance et ne peut pas être accédé. Réessayez plus tard.",
		line2 : "Notre équipe fait tout pour que le site soit de nouveau accèssible au plus vite.",
		thanks : "Merci de votre compréhension.",
	},


	pandgSpecial: {
		privacy : "Notification de confidentialité",
		conditionsPdf: "Conditions de l'action",
		conditionsGeneral: "Conditions d’utilisation"
	},

	footer: {
		genterms: "Conditions générales",
	},

	gdpr: {
		title: "Avis de confidentialité",
		client: "P&G Health Belgium SRL",
	},

	onetrust: {
		cookie: "Gestion des cookies",
	},

	navigation: {
		doSurvey: "Répondre à l'enquête",
		skipSurvey: "Demander le cashback",
		previous: "Page précédente",
		next: "Page suivante",
		claimCashback: "Demander mon cashback",
		signup: "M'inscrire !",
		toCustomerWebsite: "Vers notre site",
		readMoreOnWebsite: "En savoir plus",
	},

	breadcrumbSteps: {
		registration: "Enregistrez-vous",
		store: "Faites vos achats",
		form: "Remplissez le formulaire",
		survey: "Répondez à un questionnaire optionnel",
		cashback: "Recevez votre remboursement"
	},

	formHeader: {
		topText: "Pour vous…",
		bottomText: `* À L’ACHAT D’UN TEST D’OVULATION DIGITAL AVANCÉ CLEARBLUE<sup>®</sup> ET D’UN OMNIBIONTA<sup>®</sup> PRONATAL 12 SEMAINES`,
		macaronText: `remboursés&nbsp;!<br>À l'achat<br>de Clearblue et<br>Omnibionta`
	},

	pageTitles: {
		maxReached: "Maximum de participations atteint",
		wrongCode: "Mauvais code",
		alreadySend: "Participation déjà envoyée",
		confirmation: "Confirmation",
		beforeStart: "Cette action n'est pas encore disponible.",
		actionEnded: "Cette action est terminée.",
	},

	sectionTitles: {
		inscription: "Inscrivez-vous ici",
	},

	pageLitteracy: {
		maxReached : "Cette action est terminée car elle a atteint son maximum de participations.",
		wrongCode: `La clé fournie est erronée. Vous devez utiliser le lien que vous a envoyé CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup>. Mais peut-être souhaitez-vous vous inscrire à l'action ?`,

		alreadySend: "Vous avez déjà envoyé votre participation.",
		registration : {
			byRegistering: "En m'inscrivant à cette action, j'accepte ",
			privacyNotice: "la politique en matière de vie privée",
			claimMail : "S'inscrire par e-mail",
			claimWhatsapp: "S'inscrire par Whatsapp",
			claimSms: "S'inscrire par SMS",
			or: "Ou",
			whatsappTitle: "Cliquez sur le bouton Whatsapp",
			whatsappInfo: "Une fenêtre s'ouvrira, vous serez ensuite invité à envoyer le mot Clearblue-FR depuis votre Whatsapp.",
		},
		confirmInscription: {
			thanks: "Merci pour votre inscription !",
			smsLine1 :"Vous allez très vite recevoir un SMS avec tous les détails.",
			smsLine2: "Aller voir !",
			whatsappLine1: "Vérifiez votre téléphone",
			whatsappLine2: "Toutes les informations ont été envoyées par Whatsapp",
			mailLine1: "Vous allez très vite recevoir un e-mail avec tous les détails.",
			mailLine2: "Découvrez cet e-mail !",
		},
		form1: {
			mainTitle: "Demandez votre cashback maintenant !",
			aboutYou: "Dites-nous en plus sur vous",
			aboutProduct: "À propos de notre produit",
			popTitle : "Preuve d'achat",
			aboutYourAnimal : "À propos de votre chien",			
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Répondez encore à une enquête et obtenez un e-coupon de 1.5€ valable sur votre prochain achat",
		},
		survey: {
			title: '',
			about: "Enquête",
		},
		confirmation: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions pour votre participation à l'action CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup>.`,
				p2: "Vous recevrez votre remboursement endéans les 6 semaines pour autant que vous ayez rempli les conditions générales.",
				coupon: "Nous avons envoyé votre e-coupon à l'adresse e-mail indiquée. Profitez-en&nbsp;!",
				p3: "Bien à vous,",
				p4: `L'équipe CLEARBLUE<sup>®</sup> & OMNIBIONTA<sup>®</sup>`
			},
		},
		actionEnded: {
			text: "N'hésitez pas à visiter régulièrement notre site pour vous tenir informé des actions futures."
		},
		beforeStart: {
			text: "Vous pourrez y accéder à partir du "
		},
	},





	inputLabel: {
		defaultListHelper: "Choisissez une proposition dans la liste",
		mandatoryFields: "Champs obligatoires",

		optin : {
			label : "Inscription aux e-mails de marketing.",
			helper : "",
			complement: `Vous souhaitez recevoir des e-mails, des offres et des bons de réduction de la part de Clearblue<sup>®</sup> & Omnibionta<sup>®</sup> et d’autres <a target="_blank" href="https://www.pg.com/brands">marques de confiance</a> de P&G.`,		},

			conditions : {
				part1 : "J'accepte les",
				link: "conditions générales",
				part3: ""
			},

			title : {
				label : "Titre",
				placeholder : "Comment pouvons-nous vous appeler ?",
				helper : "",
			},
			firstname: {
				label: "Indiquez votre prénom",
				placeholder : "Prénom",
				helper: "",
			},
			lastname: {
				label: "Indiquez votre nom",
				placeholder : "Nom",
				helper: "",
			},
			email : {
				label: "Indiquez votre e-mail ici",
				placeholder: "nom{'@'}exemple.be",
				helper: "Votre e-mail doit comporter un {'@'}"
			},
			phone : {
				label: "Indiquez votre numéro de mobile",
				placeholder: "Ex : 0477123456",
				helper: "",
			},
			iban : {
				label: "Indiquez votre numéro IBAN",
				placeholder: "ex : BE00123000456000",
				helper: "Le numéro doit commencer par BE ou LU"
			},
			product: {
				label: "Quel produit avez-vous acheté ?",
				placeholder: "Choisissez une réponse…",
				helper: ""
			},
			retailer: {
				label: "Où avez-vous acheté le produit ?",
				placeholder: "Choisissez une réponse…",
				helper: ""
			},
			freeRetailer: {
				label: "Dans quelle pharmacie avez-vous acheté vos produits CLEARBLUE® et OMNIBIONTA® ?",
				placeholder: "…",
				helper: ""
			},
			datePurchase : {
				label: "Quand avez-vous acheté vos produits CLEARBLUE® et OMNIBIONTA® ?",
				placeholder: "jj/mm/aaaa",
				helper: "",
			},
			dropdown : {
				placeholder: "Choisissez une réponse…",
				helper: ""
			},
			empty : {
				label: "",
				placeholder: "",
				helper: ""
			},
			cnk1 : {
				label: "Quel est le code CNK du produit Clearblue acheté ?",
				placeholder: "0000-000",
				helper: "Le format est : XXXX-XXX",
				error: "Le code CNK n'est pas correct",
			},
			cnk2 : {
				label: "Quel est le code CNK du produit Omnibionta Pronatal acheté ?",
				placeholder: "0000-000",
				helper: "Le format est : XXXX-XXX",
				error: "Le code CNK n'est pas correct",
			},

        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation


        
/*
        typePassword : "Indiquez votre mot de passe",
        helpPassword : "Votre mot de passe doit contenir X, Y et Z",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        */


        upload: {
        	state: "statut",
        	uploadYourFile: "Envoyer vos fichiers",
        	clickOrDrop: "Cliquez ou déposer votre ticket ici.",
        	rules: "L'image doit être un fichier de type : JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
        	remove: "Retirer",
        	waiting: "En attente de l'envoi…",
        	uploaded: "Envoyé !",
        	error: "Echoué",
        	uploadsDone: "Upload réussi",
        	onlyImages: "Seulement des images sont acceptées.",
        	maxSize: "Taille maximum de 20 Mo.",
        	uploadFile: "Télécharger le fichier",
        	generalError: "Erreur, veuillez ressayer plus tard"
        }
},
}
