<template>
	<div class="infobox" :class="{'info':msgType==='info', 'alert':msgType==='alert'}">
		<div class="icon">
			
			<i class="fas fa-info-circle" v-if="msgType==='info'"></i>
			<i class="fas fa-exclamation-triangle" v-if="msgType==='alert'"></i>

		</div>
		<div class="box">
			<div class="closeButton" @click="sendCloseSignal"></div>
			<slot name="text"></slot>
		</div>
		
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Toaster",
		props: {
			msgType : {
				type: String, 
				required: false,
				default: "info"
			}
		},
		methods: {
			sendCloseSignal(){
				this.$emit('closeMe');
			},
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");


	.infobox {

		position: fixed;
		top:50px;
		right:0;

		margin: 0 auto;
		display: grid;
		grid-template-columns: 40px 1fr;
		background: #fff;

		z-index:9999999999;

		transition: transform 0.4s ease-in-out;

		transform: translateX(110%);
		&.active {
			transform: translateX(0%);
		}


		.icon {		
			text-align:center;
			line-height:40px;
			font-size:25px;
		}

		.box {
			padding: 0.5em 1em;
			line-height:1.4em;
			position: relative;

			.close {
				position: absolute;
				right:10px;
				top:2px;
				height:1.5em;
				line-height:1.5em;
				width: 1.5em;
				text-align: center;
			}
		}

		&.info {
			.icon {
				color: #9c0;
			}
			.box {
				background: #0601;
			}
		}
		&.alert {
			.icon {
				color: #F33;
			}
			.box {
				background: #c002;
			}
		}

	}


</style>
