<template>
	<div class="groupIcon">
		
		<div class="iconWrapper">
			<!-- <img class="icon" :src="getUrl(stepName)" alt=""> -->

			<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="stepName === 'store'">
			<path d="M21.7,8.7H19c0,0,0,0,0,0l-4.3-5.9c-0.4-0.5-1.2-0.6-1.7-0.2c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.1,0.6,0.2,0.8l3.4,4.5L8,8.7
			l3.3-4.5c0.2-0.2,0.3-0.5,0.3-0.8c-0.1-0.3-0.2-0.6-0.5-0.8c-0.5-0.4-1.3-0.3-1.7,0.2L5,8.7c0,0,0,0,0,0H2.2c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.8-0.5,1.3c-0.1,0.9,0.6,1.6,1.5,1.7c0,0,0.1,0,0.1,0h0.3L4,20.5c0,0.4,0.2,0.8,0.5,1c0.3,0.2,0.7,0.3,1.1,0.3h12.9
			c0.3,0,0.7-0.1,1-0.3c0.2-0.3,0.4-0.6,0.5-0.9l1.6-8.5h0.1c0.9,0,1.6-0.7,1.6-1.6c0,0,0-0.1,0-0.1C23.3,9.5,22.7,8.8,21.7,8.7
			C21.8,8.7,21.7,8.7,21.7,8.7z M17,8.7l-3.7-4.9c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.5-0.2,0.7,0l4,5.5L17,8.7
			C17,8.7,17,8.7,17,8.7z M9.9,3.3c0.2-0.2,0.5-0.2,0.7-0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3L7.1,8.7
			c0,0,0,0,0,0H5.9L9.9,3.3z M21.7,11.3h-0.2h-0.5h-7.3c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4l0,0h7l-1.6,8.3
			c-0.1,0.2-0.2,0.4-0.3,0.6C18.8,21,18.7,21,18.5,21H5.6c-0.2,0-0.5,0-0.7-0.1c-0.1-0.1-0.1-0.3-0.1-0.5l-1.5-8.4h7
			c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-8c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-0.1,0-0.1c0-0.3,0.1-0.5,0.2-0.7C1.8,9.5,2,9.5,2.2,9.5
			h19.5c0.5,0,0.8,0.4,0.8,0.8c0,0,0,0.1,0,0.1c0.1,0.5-0.3,0.9-0.7,0.9C21.8,11.3,21.7,11.3,21.7,11.3z"/>
			<path d="M8.5,13.9c-0.3-0.3-0.6-0.4-1-0.4c-0.6-0.1-1.2,0.4-1.3,1.1c0,0.1,0,0.2,0,0.2v3.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
			l0,0l0-3.4C8.8,14.5,8.7,14.2,8.5,13.9z M8,14.8v3.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-3.4l0,0c0-0.1,0-0.2,0.1-0.3
			c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0,0.3,0,0.4,0.1C8,14.5,8,14.7,8,14.8z"/>
			<path d="M13,13.9c-0.3-0.3-0.6-0.4-1-0.4c-0.6-0.1-1.2,0.4-1.3,1.1c0,0.1,0,0.2,0,0.2v3.4c0,0.7,0.6,1.3,1.3,1.3
			c0.7,0,1.3-0.6,1.3-1.3l0-3.4C13.3,14.5,13.2,14.2,13,13.9z M12.5,14.8v3.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5l0,0v-3.4
			l0,0c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0,0.3,0,0.4,0.1C12.5,14.5,12.5,14.7,12.5,14.8L12.5,14.8z"/>
			<path d="M15.1,18.2c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3l0-3.4c0-0.3-0.1-0.7-0.3-0.9c-0.5-0.5-1.3-0.6-1.8-0.2
			c-0.3,0.3-0.5,0.7-0.5,1.1L15.1,18.2z M16,14.4c0.3-0.2,0.6-0.2,0.8,0c0.1,0.1,0.1,0.3,0.1,0.4v3.4c0,0.3-0.2,0.5-0.5,0.5
			c-0.3,0-0.5-0.2-0.5-0.5v-3.4l0,0C15.9,14.6,15.9,14.5,16,14.4L16,14.4z"/>
			<path d="M12,12c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.1-0.4,0-0.5c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.2-0.1,0.4,0,0.5C11.8,11.9,11.9,12,12,12z
			"/>
		</svg>


		<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="stepName === 'registration'">
		<path d="M9.9,13.2H6c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.4-0.7,1-0.7,1.6v1.2c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-1.2
		c0-0.4,0.2-0.7,0.5-1C5.1,14,5.6,14,6,14h3.9c0.3,0,0.6,0.1,0.9,0.3c0.2,0.3,0.3,0.6,0.3,0.9v1.3c0,0.2,0.2,0.4,0.4,0.4
		s0.4-0.2,0.4-0.4v-1.3c0-0.5-0.2-1-0.5-1.4C11,13.4,10.4,13.2,9.9,13.2z"/>
		<path d="M20.3,16.1h-5.9c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.9c0.2,0,0.4-0.2,0.4-0.4S20.5,16.1,20.3,16.1z"/>
		<path d="M20.3,12.2h-5.8c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.8c0.2,0,0.4-0.2,0.4-0.4S20.5,12.2,20.3,12.2z"/>
		<path d="M20.3,8.4h-5.8c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.8c0.2,0,0.4-0.2,0.4-0.4S20.5,8.4,20.3,8.4z"/>
		<path d="M23.4,18.5V5.6c0-0.5-0.3-1-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6L2.8,3.5h0C2.2,3.5,1.6,3.7,1.1,4C0.8,4.4,0.5,5,0.5,5.5
		l0.1,13.1c0,0.5,0.2,1,0.5,1.5c0.4,0.4,1,0.6,1.6,0.6l18.7,0c0.1,0,0.1,0,0.2,0c0.5,0,1-0.2,1.4-0.6C23.3,19.6,23.4,19,23.4,18.5z
		M22.4,19.6c-0.2,0.3-0.6,0.4-0.9,0.4H2.7c-0.4,0-0.7-0.1-1-0.4c-0.2-0.3-0.3-0.6-0.3-1L1.3,5.5c0-0.3,0.1-0.7,0.4-0.9
		C2,4.4,2.4,4.3,2.8,4.3l18.6,0.1c0.3,0,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.5,0.4,0.9l0,12.9C22.7,18.9,22.6,19.3,22.4,19.6z"/>
		<path d="M7.7,12.1c1.2,0,2.3-1,2.3-2.2c0-0.1,0-0.1,0-0.2C10.1,8.5,9.2,7.4,8,7.3c-0.1,0-0.2,0-0.3,0c-1.4,0-2.1,0.8-2.3,2.4
		C5.4,11,6.4,12,7.7,12.1C7.7,12.1,7.7,12.1,7.7,12.1z M7.7,8.1C8.5,8,9.2,8.6,9.2,9.4c0,0.1,0,0.2,0,0.3c0.1,0.8-0.5,1.5-1.4,1.6
		c-0.1,0-0.1,0-0.2,0c-0.8,0-1.5-0.7-1.5-1.5c0,0,0,0,0,0C6.3,8.6,6.7,8.1,7.7,8.1z"/>
	</svg>

	<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="stepName === 'info'">
	<path d="M16.9,19h-2.3V7.5c0-0.2-0.2-0.4-0.4-0.4H8.6c-0.2,0-0.4,0.2-0.4,0.4v3c0,0.2,0.2,0.4,0.4,0.4h0.9V19H7.1
	c-0.2,0-0.4,0.2-0.4,0.4v3.2c0,0.2,0.2,0.4,0.4,0.4h9.8c0.2,0,0.4-0.2,0.4-0.4v-3.2C17.3,19.2,17.1,19,16.9,19z M16.5,22.2H7.5v-2.4
	h2.4c0.2,0,0.4-0.2,0.4-0.4v-8.9c0-0.2-0.2-0.4-0.4-0.4H9V7.9h4.9v11.5c0,0.2,0.2,0.4,0.4,0.4h2.3L16.5,22.2z"/>
	<path d="M9.5,6.2l2.3,0c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.2,1.7-0.6c1-1,1.1-2.6,0.3-3.8c-1.1-1.2-2.9-0.7-3.8,0.1
	C9.7,2.4,9.5,3.1,9.5,3.9V6.2z M10.7,2.4c0.5-0.4,1.9-1.1,2.8-0.1c0.6,0.9,0.4,2-0.3,2.8c-0.4,0.3-0.9,0.4-1.3,0.4h-1.6l0-1.6
	C10.2,3.3,10.4,2.8,10.7,2.4z"/>
</svg>


<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="stepName === 'form'">
<g id="Form">
	<path d="M16.2,15.2H9.4C9.2,15.2,9,15,9,14.8s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,15.2,16.2,15.2z"/>
	<path d="M16.3,12.4H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,12.4,16.3,12.4z"/>
	<path d="M16.3,17.9H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,17.9,16.3,17.9z"/>
	<circle cx="6.7" cy="12" r="0.3"/>
	<circle cx="6.7" cy="14.8" r="0.3"/>
	<circle cx="6.7" cy="17.5" r="0.3"/>
	<path d="M19.1,23.3l-14-0.1c-0.4,0.1-0.9-0.1-1.2-0.4c-0.3-0.3-0.5-0.8-0.4-1.2L3.6,2.3l0,0c0-0.4,0.1-0.8,0.4-1.1
	c0.3-0.3,0.8-0.5,1.3-0.5H15l5.6,5.5l-0.1,15.6c0,0.3-0.1,0.6-0.3,0.9C19.9,23.1,19.5,23.3,19.1,23.3z M5,22.4l14.1,0.1
	c0.2,0,0.4-0.1,0.5-0.3c0.1-0.1,0.1-0.3,0.1-0.5l0.1-15.2l-5.2-5.1H5.3C5,1.5,4.8,1.6,4.6,1.8C4.4,1.9,4.4,2,4.4,2.2L4.3,21.6
	c0,0.2,0,0.5,0.2,0.6C4.6,22.4,4.8,22.5,5,22.4z"/>
	<path d="M20.1,6.9H16c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V1.1h0.8v4.2c0,0.2,0.1,0.4,0.2,0.5C15.5,6,15.8,6.1,16,6.1
	h4.1V6.9z"/>
</g>
</svg>

<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 23.2 23.2" style="enable-background:new 0 0 23.2 23.2;" xml:space="preserve" v-if="stepName === 'cashback'">
<path d="M9.7,13.6C9.7,13.6,9.7,13.6,9.7,13.6c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.3-0.3-0.4c-1.9-0.6-3.5-2.2-4-4.2
C4.8,5.8,6.2,2.6,9,1.3c1.7-0.8,3.6-0.8,5.3,0c1.6,0.7,2.8,2.2,3.2,3.9c0.7,2.2,0.1,4.5-1.6,6.1V9.7c0-0.2-0.2-0.4-0.4-0.4
c-0.2,0-0.4,0.2-0.4,0.4v2.7l2.9-0.4c0.2,0,0.4-0.2,0.3-0.4c0,0,0,0,0,0c0-0.2-0.2-0.4-0.4-0.3c0,0,0,0,0,0l-1.2,0.1
c1.6-1.8,2.1-4.2,1.4-6.5c-0.5-1.9-1.8-3.5-3.6-4.4c-1.9-0.9-4.1-0.9-5.9,0C5.5,2.1,4,5.6,5.1,8.9C5.8,11.1,7.5,12.9,9.7,13.6z"/>
<path d="M8.6,7.7c0,0.2,0.2,0.4,0.4,0.4h0l0.7,0c0.1,0.3,0.3,0.6,0.5,0.9c0.4,0.5,1.1,0.8,1.8,0.8c0.5,0,1-0.1,1.4-0.4
c0.2-0.1,0.2-0.3,0.1-0.5S13.2,8.7,13,8.8c-0.7,0.4-1.7,0.3-2.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4l1,0c0.2,0,0.4-0.2,0.4-0.4
c0,0,0,0,0,0c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0l-1.3,0.1c-0.1-0.2-0.1-0.5,0-0.7L12,6.5c0.2,0,0.4-0.2,0.4-0.4
c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0l-1.6,0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.5-0.7,1.5-0.9,2.1-0.4c0,0,0,0,0,0C13,5,13.3,5,13.4,4.8
s0.1-0.4-0.1-0.5l0,0c-1-0.8-2.4-0.6-3.2,0.4c0,0,0,0,0,0c-0.2,0.3-0.4,0.7-0.5,1L9,5.8C8.8,5.8,8.6,6,8.6,6.2
c0,0.2,0.2,0.4,0.4,0.4h0l0.4,0c0,0.2,0,0.5,0,0.7L9,7.3C8.8,7.3,8.6,7.5,8.6,7.7z"/>
<path d="M22.5,13.1c-0.6-0.6-1.6-0.6-2.2-0.1l-0.2,0.3c-0.3-0.2-0.5-0.3-0.8-0.5c-0.5-0.2-1,0-1.4,0.3l-2.5,3c0,0,0,0,0,0L9.7,14
c-1.6-0.4-3.3-0.6-4.9-0.5c-1.7,0.2-3.3,1-4.6,2.1C0,15.8,0,16,0.1,16.2c0.1,0.1,0.4,0.2,0.5,0c1.2-1,2.6-1.7,4.2-1.9
c1.6-0.1,3.1,0.1,4.6,0.5l5.4,2.1c0.4,0.3,0.6,0.8,0.5,1.3c-0.2,0.4-0.6,0.7-1.1,0.8l-4-1.1C10,17.7,9.8,17.8,9.7,18
c-0.1,0.2,0.1,0.4,0.3,0.5l4.1,1.1l0.1,0c0.8,0,1.5-0.5,1.8-1.2c0.1-0.1,0.1-0.3,0.1-0.4l1-0.1l3.7-4.3c0.4-0.3,0.9-0.2,1.2,0.1
c0.4,0.2,0.5,0.7,0.3,1.1c0,0,0,0.1,0,0.1l-3.2,4L12,22.4l-3.6-1.5c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5l4,1.7l7.5-3.7
l3.4-4.2C23.4,14.6,23.3,13.7,22.5,13.1C22.6,13.1,22.5,13.1,22.5,13.1z M16.8,17.1L16,17.2c-0.1-0.2-0.2-0.3-0.3-0.5l2.5-3
c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.2,0.6,0.4L16.8,17.1z"/>
<path d="M6.6,20.3l-1.5-0.7l-4.8,0.1C0.2,19.6,0,19.8,0,20c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0L5,20.3l1.3,0.7c0.1,0,0.1,0,0.2,0
c0.2,0,0.4-0.2,0.4-0.4C6.9,20.5,6.8,20.3,6.6,20.3z"/>
</svg>

<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="stepName === 'survey'">
<path d="M18.4,4.3L16.7,6l-0.6-0.7c-0.1-0.2-0.4-0.2-0.5,0s-0.2,0.4,0,0.5l0.9,1c0.1,0.1,0.2,0.1,0.3,0.1h0c0.1,0,0.2,0,0.3-0.1l2-2
c0.1-0.1,0.2-0.4,0-0.5C18.8,4.1,18.6,4.1,18.4,4.3C18.4,4.3,18.4,4.3,18.4,4.3L18.4,4.3z"/>
<path d="M22.6,1.4C22.3,1.2,21.9,1,21.5,1l-8.2,0c-0.4-0.1-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.4,1.2l-0.1,5.7
c0,0.4,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.4,1.1,0.4l1.1-0.1V12c0,0.2,0.2,0.4,0.4,0.4c0.1,0,0.2,0,0.3-0.1l2.6-2.5h4
c0.4,0,0.8-0.2,1.1-0.4C22.8,9,23,8.7,23,8.3V2.5C23,2.1,22.8,1.7,22.6,1.4z M22.2,8.3c0,0.2-0.1,0.4-0.2,0.5C21.9,9,21.7,9,21.5,9
h-4.2c-0.1,0-0.2,0-0.3,0.1l-2.1,2V9.4C15,9.2,14.8,9,14.6,9l0,0l-1.5,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.4-0.3-0.6l0.1-5.8
c0-0.2,0-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2h8.3c0.2,0,0.4,0.1,0.5,0.1c0.1,0.2,0.2,0.4,0.2,0.6V8.3z"/>
<path d="M6.6,11.1c1.6,0.1,3-1.1,3.1-2.7c0-0.1,0-0.3,0-0.4C9.6,6.4,8.2,5.1,6.6,5C5,4.9,3.6,6.1,3.5,7.7c0,0.1,0,0.2,0,0.3
c-0.1,1.6,1,2.9,2.6,3.1C6.3,11.1,6.4,11.1,6.6,11.1z M6.6,5.8c1.2,0.1,2.2,1,2.3,2.2c0.1,1.2-0.8,2.2-2,2.3c-0.1,0-0.2,0-0.3,0
c-1.2,0.1-2.2-0.7-2.3-1.9c0-0.2,0-0.3,0-0.5c-0.1-1.2,0.9-2.2,2-2.2C6.4,5.8,6.5,5.8,6.6,5.8L6.6,5.8z"/>
<path d="M12.8,17.3l0.8-2.5c0.1-0.2,0-0.4-0.2-0.5c0,0-0.1,0-0.1,0h-0.9c0-0.7-0.3-1.3-0.7-1.8c-0.6-0.5-1.3-0.8-2-0.9h-6
c-0.7,0-1.3,0.3-1.8,0.8c-0.4,0.5-0.7,1.1-0.7,1.8l0.1,3.9c0,0.6,0.2,1.1,0.5,1.5c0.2,0.2,0.4,0.4,0.7,0.5v2.4
c0,0.2,0.2,0.4,0.4,0.4h7.9c0.2,0,0.3-0.1,0.4-0.3l0.8-2.3h0.6c0,0,0.1,0,0.1,0c0.9-0.4,1.3-1.4,0.9-2.2
C13.3,17.8,13.1,17.5,12.8,17.3L12.8,17.3z M1.9,18.1l-0.1-3.9c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.8-0.6,1.2-0.6h6
c0.6,0.1,1.1,0.3,1.5,0.7c0.3,0.4,0.5,0.8,0.5,1.3H8c-0.2,0-0.3,0.1-0.4,0.3L6.8,17H4.4v-2.3c0-0.2-0.2-0.4-0.4-0.4
c-0.2,0-0.4,0.2-0.4,0.4v2.7c0,0.2,0.2,0.4,0.4,0.4h2.6L6,19.6H3.3c-0.4,0-0.8-0.2-1.1-0.5C2,18.8,1.9,18.5,1.9,18.1z M3.1,20.4
c0.1,0,0.2,0,0.2,0h2.4l-0.6,1.9H3.1L3.1,20.4z M10.3,22.2H5.9l2.4-7.1h4.4L12,17.2l-1.3-0.1c-0.1,0-0.2,0-0.3,0.1
c-0.1,0.1-0.1,0.2-0.1,0.3V20c0,0.2,0.2,0.4,0.4,0.4H11L10.3,22.2z M12.3,19.6h-1.2v-1.7l1.3,0.1c0.3,0.2,0.4,0.5,0.4,0.8
C12.8,19.1,12.6,19.5,12.3,19.6L12.3,19.6z"/>
</svg>




<span v-if="isValid" class="pastille valid">&nbsp;</span>
<span v-if="!isValid" class="pastille">{{stepNumber + 1}}</span>
</div>

<span class="label">{{$t('breadcrumbSteps.' + stepName)}}</span>

</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Crumbs",
		props: {
			stepName : {
				type: String,
				required: true
			},
			stepNumber : {
				type: Number,
				required: true
			},
			isValid : {
				type: Boolean,
				required: true
			}
		},
		methods: {
			getUrl(name) {
				return require('@/assets/img/ico_' + name + '.svg');
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	$small_threshold : 560px;
	$medium_threshold : 860px;
	$big_threshold : 1040px;

	.groupIcon {
		display: flex;
		gap:14px;
		margin:0 auto;

		@media screen and (min-width : $small_threshold) {
			width:180px;
		}

		.iconWrapper {
			position: relative;
		}
		.icon {
			margin: 0 auto;
		}

		@media screen and (max-width : $small_threshold) {
			margin-bottom:1em;
			flex-direction: column;
			text-align: center;
			.icon {
				width:45px;
				height:45px;
			}
			span.label {
				font-size:12px;
				max-width:120px;
			}
		}
		@media screen and (min-width : $small_threshold) {
			flex-direction: row;
			text-align: left;
			margin-bottom:1.5em;
			.iconWrapper {
				margin-right:1.5em;
			}
			.icon {
				width:65px;
				height:65px;
			}
			span.label {
				font-size:0.9em;
			}

		}
		@media screen and (min-width : $medium_threshold) {
			flex-direction: column;
			text-align: center;
			.icon {}
			span.label {}
			.iconWrapper {
				margin-right:0;
			}
		}
		@media screen and (min-width : $big_threshold) {
			.icon {}
			span.label {}
		}

	}

	.label {
		justify-self: start;
		align-self: center;
		line-height:1.2em;
		@media screen and (max-width : $small_threshold) {
			display: inline-block;
			margin-top:0.5em;
		}
		@media screen and (min-width : $small_threshold) {
			padding:0;
		}
	}

	span.pastille {
		position: absolute;
		bottom:-2px;
		padding:0 !important;
		display: inline-block;

		width:24px;
		border-radius:400px;
		line-height:24px;
		text-align: center;
		font-weight: bold;
		font-size:1rem;
		@media screen and (max-width : $small_threshold) {
			right:50%;
			transform: translateX(50px);
		}
		@media screen and (min-width : $small_threshold) {
			right:50%;
			transform: translateX(40px);
		}
	}
	span.pastille.valid {
		background: url('../assets/img/tick-mark.svg') no-repeat center center / cover;
	}
</style>
