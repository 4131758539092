<template>
  <div class="aCustomInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req">*</span></label>
    <input
    :id="fieldname"
    :type="inputType"
    v-model="modelValueComputed"
    :placeholder="placeholder"
    :disabled="disabled"
    :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
    >
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
import { debounce } from "lodash";
import FormFields from "../mixins/FormFields";
import dayjs from "dayjs";
import config from "../config";
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
export default {
  name: "customInput",

  emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

  mixins: [FormFields],

  props: {
    inputType:{
      type: String,
      validator : function(value){
        return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
      }
    },
    label : {
      type: String,
      required : true
    },
    disabled : {
      type: Boolean,
      required: false,
      default: false
    },
    valueHasChanged: {
      type: String,
      required: false
    },
  },

  data(){
    return {
      errorMessage : "",
      config: config,
    }
  },

  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.validateMyselfDebounced();
        this.$emit('update:modelValue', newVal);
      }
    }
  },

  methods : {
    validateMyself() {
      if(this.inputType === "date") {
        return this.dateCheck()
      }
      this.setLoading();



      console.group("CustomInput Validation " + this.fieldname);
      console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);
      console.groupEnd();

      let data = {
        [this.fieldname]: this.modelValueComputed
      };

      this.axios.post(
        `/lead/${config.idlead}/data/${this.fieldname}/validate`,
        data
        )
      .then(res => {
        console.log("I got a positive CustomInput result: ");
        console.log(res);
        // A non-required field must be neutral if empty
        if(this.modelValueComputed.length > 0){
          this.giveSuccess();
          this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
        }else{
          this.removeSuccessOrError();
        }  
        this.setNotLoading();
      })
      .catch(error => {
        console.log("CustomInput reported an error:");
        console.log("--> " + error.response.data.errors[this.fieldname][0]);
        this.giveError();
        this.errorMessage = error.response.data.errors[this.fieldname][0];
        this.setNotLoading();
        this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
      })
      .then(()=>{});
    },


    /* TODO : add a check: is date inbetween purchase dates from lead */
    /* use then this.$t('errors.dateOutOfPeriod'); */
    dateCheck() {
      this.dateMessage = null;
      let date = dayjs(this.modelValueComputed);
      if(!date.isValid()) {
        this.errorMessage = this.$t('errors.dateInvalid');
        this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
      } else if(!date.isSameOrBefore(dayjs())) {
        this.errorMessage = this.$t('errors.dateBefore');
        this.giveError();
        this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
      } else {
        this.giveSuccess();
        this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
      }
    },



  },

  created() {
    this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
    if(this.modelValueComputed) {
      this.validateMyselfDebounced();
    }
  }
}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
