<template>
  <div class="aPhoneInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req">*</span></label>
    <input
    ref="phone"
    type="tel"
    v-model="phoneNumber"
    :placeholder="placeholder"
    :disabled="disabled"
    :id="fieldname"
    :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
    @countrychange="sendInternationalFormat">
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ================================================================================== -->

<script>
 import 'intl-tel-input/build/css/intlTelInput.css';
 import intlTelInput from 'intl-tel-input';
 import FormFields from "../mixins/FormFields";
 import {debounce} from "lodash";
 import config from "../config";
 export default {
  name: "PhoneInput",
  mixins: [FormFields],
  props: {
    modelValue: {
      required: true,
      type: String
    },
    initialCountry: {
      required: false,
      type: String,
      default: 'BE'
    },
    preferredCountries: {
      required: false,
      type: Array,
      default: () => ["be", "lu", "nl"]
    },
    countries: {
      required: false,
      type: Array,
      default: () => ["al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk",
      "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie", "it", "lv",
      "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro",
      "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "gb"]

    },
    label : {
      type: String,
      required : true
    },
    receivedNumber: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      phonejs: '',
      config: config,
      disabled: false,
    }
  },
  methods: {
    sendInternationalFormat() {
      let phoneToEmit = this.phonejs.getNumber();
      if (phoneToEmit != ""){
        this.$emit('internationalNumber', phoneToEmit); 
      }else{
        console.log("phonejs.getNumber() returns an empty value!");
        console.log("see in PhoneInput.vue > sendInternationalFormat()");
      }

    },

    lockThisField() {
      this.disabled = true;
    },

    validateMyself() {
      this.setLoading();
      let data = {
        [this.fieldname]: this.phonejs.getNumber()
      }
      this.axios.post(`/lead/${config.idlead}/data/${this.fieldname}/validate`, data)
      .then(() => {
        this.giveSuccess();
        this.setNotLoading();
      }).catch(error => {
        this.giveError();
        this.errorMessage = error.response.data.errors[this.fieldname][0];
        this.setNotLoading();
      })
    }
  },
  computed: {
    phoneNumber: {
      get: function () {
        return this.modelValue;
      },
      set: function (newval) {
        this.validateMyselfDebounced();
        this.$emit('update:modelValue', newval);
        this.sendInternationalFormat();
      }
    },
  },
  mounted() {
    this.phonejs = intlTelInput(this.$refs.phone, {
      initialCountry: this.initialCountry,
      autoHideDialCode: false,
      utilsScript: require('intl-tel-input/build/js/utils'),
      onlyCountries: this.countries,
      preferredCountries: this.preferredCountries,
      nationalMode: true,
      customContainer: "fullwidth",
    });

    this.validateMyselfDebounced = debounce(this.validateMyself, 1000);
      // run validation immediately when the value is not null or empty
      if(this.phoneNumber) {
        this.validateMyselfDebounced();
        this.sendInternationalFormat();
      }

      // If a phone number is received from From.vue, display it.
      if(this.receivedNumber && this.receivedNumber.length > 0){
        this.phoneNumber = this.receivedNumber;
        this.lockThisField();
      }
    },
  }
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
