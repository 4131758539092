// Cookiefirst
export default {



	methods: {
		putCookieFirstScriptInPlace(){
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);
			script.setAttribute('data-cookiefirst-key',`d146e288-6b69-4f0e-893c-dfd47c660433`);
			document.body.prepend(script);
		}
	},
	beforeMount() {
		// this.putCookieFirstScriptInPlace();
	}

}
