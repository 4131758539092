<template>

	<div id="layoutGen">

		<!-- =============== FORM =============== -->
		<div id="formWrapper">
			<div id="contentColumn" class="action-wrapper">

				<!-- ========== LANG ========== -->
				<lang-switcher></lang-switcher>


				<!-- LOGOs -->
				<div id="header">

					<div id="logos">
						<div class="logo pronatal">
							<img src="@/assets/img/logo-pronatal.png" alt="Logo Pronatal">
						</div>
						<div class="logo clearblue">
							<img src="@/assets/img/logo-clearblue.png" alt="Logo Clearblue">
						</div>
					</div>
					
					<div id="heroimage">
						<img src="~@/assets/img/couple.jpg" alt="Couple" class="heroimage">

						<div id="group_packshot">
							<div class="packshot nl" v-if="this.$i18n.locale === 'nl_BE'">
								<img src="~@/assets/img/packshots_nl.png" alt="Packshots" class="packshots" >
							</div>
							<div class="packshot fr" v-else>
								<img src="~@/assets/img/packshots_fr.png" alt="Packshots" class="packshots" >
							</div>						
						</div>

						<div id="macaron" :class="{'nl' : this.$i18n.locale === 'nl_BE', 'fr' : this.$i18n.locale !== 'nl_BE'}">
							<div class="macacontent">
								<div class="big">10<span style="font-size:0.8em;">€<sup>*</sup></span></div>
								<div class="small" v-html="$t('formHeader.macaronText')"></div>
							</div>
						</div>
					</div>
					
					<div id="smalltalk" class="smallCaracters" v-html="$t('formHeader.bottomText')"></div>

				</div>


				<!-- ========== FORM ========== -->
				<div id="formPaper">
					<router-view></router-view>
				</div>
				<!-- ========== // ========== -->







				<!-- ========== FOOTER ========== -->
				<div id="footer">

					<ul>
						<li>
							<a :href="$t('pandgSpecial.conditionsPdf')" @click.prevent="openPDFFile('conditions')" download>{{$t('pandgSpecial.conditionsPdf')}}</a>
						</li>
						<li>
							<a v-if="this.$i18n.locale === 'nl_BE'" href="https://privacypolicy.pg.com/nl/" target="_blank">{{$t('pandgSpecial.privacy')}}</a>
							<a v-if="this.$i18n.locale != 'nl_BE'" href="https://privacypolicy.pg.com/fr/" target="_blank">{{$t('pandgSpecial.privacy')}}</a>
						</li>
						<li>
							<a v-if="this.$i18n.locale === 'nl_BE'" href="https://termsandconditions.pg.com/nl-nl/" target="_blank">{{$t('pandgSpecial.conditionsGeneral')}}</a>
							<a v-if="this.$i18n.locale != 'nl_BE'" href="https://termsandconditions.pg.com/fr-be/" target="_blank">{{$t('pandgSpecial.conditionsGeneral')}}</a>
						</li>
					</ul>


					<ul style="margin-top:2em;">
						<!-- Link must be dynamic following the language -->
						<li>CONTACT :</li>
						<li>
							<a v-if="this.$i18n.locale === 'nl_BE'" href="mailto:infolijn@promolife.be">infolijn@promolife.be</a>
							<a v-if="this.$i18n.locale != 'nl_BE'" href="mailto:infoligne@promolife.be">infoligne@promolife.be</a>
						</li>
						<li>
							<a v-if="this.$i18n.locale === 'nl_BE'" href="tel:+3223520390">+32 2 352 03 90</a>
							<a v-if="this.$i18n.locale != 'nl_BE'" href="tel:+3223520398">+32 2 352 03 98</a>					
						</li>
					</ul>

				</div>
				<!-- ========== // ========== -->



			</div> <!-- END #content-->
		</div> <!-- END #right -->
	</div> <!-- ===== END .layoutGen ===== -->





	<!-- GDPR -->
<!--
As soon as it is available in the LeadManagement, replace client line by this one:
:client="leadGDPR.fabname?.trim()"
-->
<gdpr
:client="$t('gdpr.client')"
:client-adress="leadGDPR.gdprstreet?.trim() + ' ' + leadGDPR.gdprstreetnum?.trim() + ', ' + leadGDPR.gdprcp?.trim() + ' ' + leadGDPR.gdprloc?.trim() + ' ' + leadGDPR.gdprcountry?.trim()"
contact-phone=""
:contact-fname="leadGDPR.gdprfname?.trim()"
:contact-name="gdprName"
:contact-email="leadGDPR.gdprmail?.trim()"
@gdprClosing="insertPixel"
></gdpr>


<input name="btnOneTrust" type="button" id="btnOneTrust" class="btnOneTrust" :value="$t('onetrust.cookie')" onclick="Optanon.ToggleInfoDisplay();">




</template>







<script>

	// import Headings from '@/components/Headings.vue';
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import GDPR from '@/components/GDPR.vue';
	import LangSwitcher from '@/components/LangSwitcher.vue';
	import pixel from "./mixins/pixel";
	import ga from "./mixins/ga";
	import cf from "./mixins/cf";
	import config from "./config";

	export default {
		title: 'Clearblue | Pronatal',
		name: 'App',
		data() {
			return {
				idLead : config.idlead,
				leadGDPR : {},
			}
		},
		components: {
			LangSwitcher,
			'gdpr': GDPR
		},
		mixins: [pixel, ga, cf],
		methods: {
			openPDFFile (name='conditions'){
				let link = config.assetPath + "files/" + name + "_";
				window.open(link + this.$i18n.locale.toLowerCase() +".pdf");
			},
			getLangFromUrl () {
				this.$i18n.locale = "nl_BE";
				let urlParams = new URLSearchParams(window.location.search);
				let lang = '';
				if (urlParams.has('lang')){
					lang = urlParams.get('lang');
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						this.$i18n.locale = "fr_BE";
					}
				}
			},

			insertPixel() {
				// this.deployAndLog();
			},

			getGDPRData(idLead){
				console.log("Getting Leads for " + this.idLead);
				this.axios.get(
					`/leads/${idLead}`
					)
				.then(res => {
					console.log("I managed to get the GDPR leads! ");
					this.iGotTheGDPRLead(res);
				})
				.catch(error => {
					console.error("I cannot get the GDPR Leads");
					this.iCannottGetTheGDPRLead(error);
				})
				.then(()=>{
				});
			},

			iGotTheGDPRLead(res){
				console.log(res.data);
				this.leadGDPR = res.data;
			},

			iCannottGetTheGDPRLead(error){
				console.log(error.response.data.errors);
				// this.displayMessage(error.response.data.errors, 'alert');
			},

		},

		computed: {
			gdprName() {
				if(this.leadGDPR && this.leadGDPR.gdprname && this.leadGDPR.gdprname?.trim() !== '') {
					return this.leadGDPR.gdprname?.trim()?.uppercase();
				} else {
					return "";
				}
			}
		},

		beforeMount(){
			this.getLangFromUrl();
			this.getGDPRData(this.idLead);
		},
		mounted(){
			// Added for vue devtool to work
			window.postMessage({ devtoolsEnabled: true, vueDetected: true });
		}
	}

</script>


<style lang="scss">
</style>



