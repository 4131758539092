<template>
	<div id="formsContainer">

		<!-- DISPLAY MESSAGES -->
		<toaster	 :class="{'active':isThereMessage}" :msgType="messageType" @closeMe="isThereMessage = false;">
			<template v-slot:text>
				<span style="font-size:0.8em; color: #C00;">{{messageTitle}}</span>
				<ul>
					<li v-for="(message, index) in messageContent" :key="index">&rtrif; {{message}}</li>
				</ul>
			</template>
		</toaster>


		<transition name="fade" mode="out-in">

			<page-form :title="$t('pageTitles.maxReached')" :grid="false" v-if="currentPage==='maxreached'" key="maxreached">
				<template v-slot:content>
					<!-- ================================================ -->
					<div class="col4 simpletext">
						<p>{{$t('pageLitteracy.maxReached')}}</p>
					</div>
					<!-- ================================================ -->
				</template>
			</page-form>









			<page-form :grid="true" v-else-if="currentPage==='inscription'" key="inscription">
				<template v-slot:content>

					<breadcrumbs
					class="col4"
					:steps="['registration','store','form','cashback']"
					:currentStep="0"
					></breadcrumbs>

					<claim-blocs
					class="col4"
					:bloc-type="['email', 'whatsapp', 'sms']"
					@goto="goto"
					@mean="setInscriptionMean"
					:lang="$i18n.locale.slice(0,2)"
					:idLead="lead.id"
					@errorpresent="anErrorOccured"
					>
					<template v-slot:title>
						<headings titletype="titleh3" class="col4">
							<template v-slot:text>{{$t('sectionTitles.inscription')}}	</template>
						</headings>
					</template>
				</claim-blocs>

<!-- XXXX - LINKS href="#" TO BE COMPLETED IF NEEDED
				<div class="disclaimer col4">

					{{$t('pageLitteracy.registration.byRegistering')}} <a v-if="$i18n.locale==='fr_BE' || $i18n.locale==='fr_LU'" href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a><a v-else href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a>
				</div>
			-->
			<!-- ================================================ -->
		</template>
	</page-form>


















	<page-form :title="$t('pageTitles.confirmation')" :grid="true" v-else-if="currentPage==='confirminscription'" key="confirminscription">
		<template v-slot:content>
			<!-- ================================================ -->

			<breadcrumbs
			class="col4"
			:steps="['registration','store','form','cashback']"
			:currentStep="1"
			></breadcrumbs>

			<headings titletype="titleh3" class="col4">
				<template v-slot:text>{{$t('pageLitteracy.confirmInscription.thanks')}}</template>
			</headings>

			<spacer :h="1" class="col4"></spacer>

			<div id="confirmSMS" v-if="inscriptionMean==='phone'" class="col4 centeredContent">
				<p>{{$t('pageLitteracy.confirmInscription.smsLine1')}}</p>
				<p>{{$t('pageLitteracy.confirmInscription.smsLine2')}}</p>
			</div>

			<div id="confirmWhatsapp" v-if="inscriptionMean==='whatsapp'" class="col4 centeredContent">
				<p>{{$t('pageLitteracy.confirmInscription.whatsappLine1')}}</p>
				<p>{{$t('pageLitteracy.confirmInscription.whatsappLine2')}}</p>
			</div>

			<div id="confirmMAIL" v-if="inscriptionMean==='email'" class="col4 centeredContent">
				<p>{{$t('pageLitteracy.confirmInscription.mailLine1')}}</p>
				<p>{{$t('pageLitteracy.confirmInscription.mailLine2')}}</p>
			</div>

			<!-- ================================================ -->
		</template>

	</page-form>

















	<page-form :title="$t('pageTitles.wrongCode')" :grid="true" v-else-if="currentPage==='wrongcode'" key="wrongcode">
		<template v-slot:content>
			<!-- ================================================ -->
			<div class="col4 simpletext">
				<p v-html="$t('pageLitteracy.wrongCode')"></p>
			</div>
			<!-- ================================================ -->
		</template>

		<template v-slot:nav>
			<nav-buttons :next="{label:$t('navigation.signup'), dest:'inscription'}" @goto="goto"></nav-buttons>
		</template>

	</page-form>





































	<page-form :title="$t('maintenance.title')" :grid="false" v-else-if="currentPage==='maintenance'" key="maintenance">
		<template v-slot:content>
			<div style="text-align:center;">
				<p>{{$t('maintenance.line1')}}</p>
				<p>{{$t('maintenance.line2')}}</p>
				<p>{{$t('maintenance.thanks')}}</p>
			</div>
		</template>
	</page-form>




	<page-form :title="$t('pageLitteracy.form1.mainTitle')" :grid="true" v-else-if="currentPage==='form1'" key="form1">
		<template v-slot:content>


			<breadcrumbs :steps="['registration','store','form','cashback']" :currentStep="2" class="col4"></breadcrumbs>

			<p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;">*</span> {{$t('inputLabel.mandatoryFields')}}</p>
			<headings titletype="titleh3" class="col4">
				<template v-slot:text>{{$t('pageLitteracy.form1.aboutYou')}}</template>
			</headings>

			<!-- TITLE -->
			<!--
			<custom-select
			fieldname="title"
			:placeholder="$t('inputLabel.title.placeholder')"
			:label="$t('inputLabel.title.label')"
			data-from="titles"
			identifier="id"
			description="description"
			:idLead="lead.id"
			:helper="$t('inputLabel.title.helper')"
			:isRequired="true"
			:isReparticipation="reparticipation"
			v-model.lazy:modelValue='fieldsValues.idtitle'
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			></custom-select>

			<spacer :h="0" class="col4"></spacer>
		-->

		<!-- FIRSTNAME -->
		<custom-input
		fieldname="firstName"
		:placeholder="$t('inputLabel.firstname.placeholder')"
		inputType="text"
		:idLead="lead.id"
		:label="$t('inputLabel.firstname.label')"
		:isRequired="true"
		:helper="$t('inputLabel.firstname.helper')"
		v-model.lazy:modelValue="fieldsValues.firstname"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		class=""></custom-input>

		<!-- LASTNAME -->
		<custom-input
		fieldname="lastName"
		:placeholder="$t('inputLabel.lastname.placeholder')"
		inputType="text"
		:idLead="lead.id"
		:label="$t('inputLabel.lastname.label')"
		:isRequired="true"
		:helper="$t('inputLabel.lastname.helper')"
		v-model.lazy:modelValue="fieldsValues.lastname"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		class=""></custom-input>

		<!-- EMAIL -->
		<custom-input
		fieldname="email"
		:placeholder="$t('inputLabel.email.placeholder')"
		inputType="text"
		:idLead="lead.id"
		:label="$t('inputLabel.email.label')"
		:helper="$t('inputLabel.email.helper')"
		:isRequired="true"
		:disabled="isMailFieldDisabled"
		v-model.lazy:modelValue="fieldsValues.email"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		></custom-input>

		<!-- PHONE -->
		<phone-input
		fieldname="phoneNumber1"
		v-model="smsHelper"
		inputType="phone"
		:idLead="lead.id"
		:placeholder="$t('inputLabel.phone.placeholder')"
		:label="$t('inputLabel.phone.label')"
		:helper="$t('inputLabel.phone.helper')"
		:isRequired="false"
		@internationalNumber="setPhone"
		:receivedNumber="fieldsValues.phone1"
		ref="phoneNumber1"></phone-input>

		<!-- IBAN -->
		<custom-input
		fieldname="IBAN"
		:placeholder="$t('inputLabel.iban.placeholder')"
		inputType="text"
		:idLead="lead.id"
		:label="$t('inputLabel.iban.label')"
		:helper="$t('inputLabel.iban.helper')"
		:isRequired="true"
		v-model.lazy:modelValue="fieldsValues.iban"
		@runCheck="checkIfMustUnlockClaimCashbackButton"></custom-input>

		<spacer :h="1" class="col4"></spacer>

		<headings titletype="titleh3" class="col4">
			<template v-slot:text>{{$t('pageLitteracy.form1.aboutProduct')}}</template>
		</headings>

		<!-- WHAT PRODUCT -->
<!--
		<popitem
		fieldname="selectProduct"
		:placeholder="$t('inputLabel.product.placeholder')"
		:label="$t('inputLabel.product.label')"
		data-from="popitems"
		identifier="id"
		description="description"
		:idLead="lead.id"
		:helper="$t('inputLabel.product.helper')"
		:isRequired="true"
		:isReparticipation="reparticipation"
		v-model.lazy:modelValue='fieldsValues.popitem'
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		class="col2"
		></popitem>
	-->

	<!-- Spacer required to force a return to the next line -->
	<spacer :h="0" class="col4"></spacer>

	<!-- WHERE the product has been bought -->
			<!--
			<custom-select
			fieldname="idPOPRetailer"
			data-from="retailers"
			identifier="id"
			description="name1"
			:placeholder="$t('inputLabel.retailer.placeholder')"
			:label="$t('inputLabel.retailer.label')"
			:helper="$t('inputLabel.retailer.helper')"
			:isRequired="true"
			:isReparticipation="reparticipation"
			v-model.lazy:modelValue="fieldsValues.retailer"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			></custom-select>
		-->

		<!-- IF "other" (id : XXX. Use "freefield : 1" instead, one day…), this field appears : -->


		<!-- DATE OF PURCHASE -->
		<custom-input
		fieldname="purchaseDate"
		inputType="date"
		:idLead="lead.id"
		:placeholder="$t('inputLabel.datePurchase.placeholder')"
		:label="$t('inputLabel.datePurchase.label')"
		:helper="$t('inputLabel.datePurchase.helper')"
		:isRequired="true"
		v-model.lazy:modelValue="fieldsValues.purchaseDate"
		@runCheck="checkIfMustUnlockClaimCashbackButton"></custom-input>

		<!-- FREE RETAILER NAME -->
		<custom-input
		fieldname="freeRetailerName"
		inputType="text"
		:idLead="lead.id"
		:placeholder="$t('inputLabel.freeRetailer.placeholder')"
		:label="$t('inputLabel.freeRetailer.label')"
		:helper="$t('inputLabel.freeRetailer.helper')"
		:isRequired="true"
		v-model.lazy:modelValue="fieldsValues.freeRetailer"
		@runCheck="checkIfMustUnlockClaimCashbackButton"></custom-input>




<!--
		<custom-input
		fieldname="idLeadTMD1"
		inputType="text"
		:idLead="lead.id"
		:placeholder="$t('inputLabel.cnk1.placeholder')"
		:label="$t('inputLabel.cnk1.label')"
		:helper="$t('inputLabel.cnk1.helper')"
		:isRequired="true"
		v-model.lazy:modelValue="fieldsValues.cnk1"
		@runCheck="checkIfMustUnlockClaimCashbackButton"></custom-input>
	-->

	<free-input
	fieldname="idLeadTMD1"
	inputType="text"
	:idLead="lead.id"
	:acceptedValues="['3060-035']"
	:placeholder="$t('inputLabel.cnk1.placeholder')"
	:label="$t('inputLabel.cnk1.label')"
	:helper="$t('inputLabel.cnk1.helper')"
	:isRequired="true"
	v-model.lazy:modelValue="fieldsValues.cnk1"
	@runCheck="checkIfMustUnlockClaimCashbackButton"></free-input>

	<free-input
	fieldname="idLeadTMD2"
	inputType="text"
	:idLead="lead.id"
	:acceptedValues="['3906-765']"
	:placeholder="$t('inputLabel.cnk2.placeholder')"
	:label="$t('inputLabel.cnk2.label')"
	:helper="$t('inputLabel.cnk2.helper')"
	:isRequired="true"
	v-model.lazy:modelValue="fieldsValues.cnk2"
	@runCheck="checkIfMustUnlockClaimCashbackButton"></free-input>






	<!-- Spacer required to force a return to the next line -->
	<spacer :h="0" class="col4"></spacer>

	<!-- Proof of purchase -->
	<headings titletype="titleh3" class="col4">
		<template v-slot:text>{{$t('pageLitteracy.form1.popTitle')}}</template>
	</headings>
	<upload
	post-upload-url-prop="/participations/upload"
	:idLead="lead.id"
	:isRequired="true"
	:idParticipationOwner="ownerData.id"
	@runCheck="checkIfMustUnlockClaimCashbackButton"
	></upload>

	<spacer :h="1" class="col4"></spacer>



	<div class="empty col1"></div>
	<!-- Custom Checkbox General Conditions -->
	<custom-checkbox
	fieldname="conditions"
	inputid="demoConditionsCB"
	helper=""
	:isRequired="true"
	:idLead="lead.id"
	v-model="fieldsValues.cg"
	@runCheck="checkIfMustUnlockClaimCashbackButton"
	special-style="vcheck"
	class="col2"
	>
	<template v-slot:msg>
		{{$t('inputLabel.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputLabel.conditions.link')}}</a>
	</template>
</custom-checkbox>
<div class="empty col1"></div>



<div class="empty col1"></div>
<!-- Pavé légal FR -->
<div id="proctelegal" class="col2" v-if="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_LU'">
	<h2>Pourquoi collectons-nous ces données&nbsp;?</h2>

	<div>
		<p>
			Vous vous inscrivez comme utilisateur de <a target="_blank" href="https://www.clearblue-pronatal-coupon.be">clearblue-pronatal-coupon.be</a> dont <a target="_blank" href="https://www.pg.com/privacy/french/privacy_statement.shtml#entities">Procter & Gamble</a> est le responsable du traitement. En vous inscrivant, vous confirmez que :
		</p>

		<h3>Vos choix.</h3>
		<p>Vous pouvez faire valoir vos droits liés à la protection des données* ou <a target="_blank" href="https://www.pg.com/privacy/french/privacy_statement.shtml#preferences">modifier vos préférences et vos abonnements</a>.</p>

		<h3>Conditions générales et politique de confidentialité.</h3>
		<p>
			Vous avez au moins 16 ans et vous avez lu et accepté les <a target="_blank" href="https://www.pg.com/fr_BE/terms_conditions/index.shtml">Conditions générales</a> et la <a target="_blank" href="https://www.pg.com/privacy/french/privacy_statement.shtml">Politique de confidentialité de P&G</a>. Pour mieux adapter nos communications à vos centres d’intérêt, nous combinerons les données** que vous fournissez ci-dessus à d’autres données que nous aurons recueillies à votre sujet en provenance de plusieurs sources et de tiers.
		</p>

		<p class="small">* Comme nous l’expliquons dans notre politique de confidentialité, vous pouvez accéder, corriger, supprimer, transférer vers un autre fournisseur, refuser ou restreindre notre traitement des données personnelles que nous détenons à votre sujet, ou retirer votre consentement.</p>
		<p class="small">** Nous recueillons et pouvons combiner les informations que nous recevons de votre part lorsque vous nous téléphonez ou que vous consultez nos sites ou applications, lorsque vous utilisez nos appareils ou que vous regardez nos publicités en ligne, ou que nous obtenons de sources disponibles sur le marché.</p>
	</div>
</div>
<!-- END Pavé légal FR -->

<!-- Pavé légal NL -->
<div id="proctelegal" class="col2" v-else>

	<h2>Waarom verzamelen wij deze gegevens?</h2>
	<div>
		<p>
			U registreert zich als een gebruiker van <a target="_blank" href="https://www.clearblue-pronatal-coupon.be">clearblue-pronatal-coupon.be</a> waar <a target="_blank" href="https://www.pg.com/privacy/dutch/privacy_statement.shtml#entities">Procter & Gamble</a> de verwerkingsverantwoordelijke voor is. Door u in te schrijven erkent u:
		</p>

		<h3>Uw keuzes.</h3>
		<p>Dat u uw privacyrechten* kunt uitoefenen of uw voorkeuren en abonnementen kunt wijzigen door <a target="_blank" href="https://www.pg.com/privacy/dutch/privacy_statement.shtml#preferences">hier</a> te klikken.</p>

		<h3>Algemene Voorwaarden en Privacybeleid.</h3>
		<p>Dat u ten minste 16 bent en de P&G <a target="_blank" href="http://www.pg.com/nl_BE/terms_conditions/index.shtml">Algemene voorwaarden</a> en het <a target="_blank" href="https://www.pg.com/privacy/dutch/privacy_statement.shtml">Privacybeleid</a>. hebt gelezen en hiermee instemt. Om onze communicatie beter op uw interesses af te stemmen, combineren we de gegevens** die u hierboven opgeeft met andere gegevens die we mogelijk over u hebben verzameld uit verschillende bronnen en bij derde partijen.
		</p>
		<p class="small">* Zoals uitgelegd in ons privacybeleid, mag u persoonsgegevens die we over u verwerken bekijken, corrigeren, wissen, aan een andere provider overdragen, kan u zich verzetten tegen deze verwerking, kan u vragen dat wij de verwerking beperken, of kan u uw toestemming intrekken.</p>
		<p class="small">** Als u ons belt, onze sites of apps bezoekt, onze apparaten gebruikt, onze online advertenties bekijkt of de advertenties bekijkt die we van commercieel beschikbare bronnen krijgen, verzamelen we de informatie die we van u ontvangen en kunnen we deze combineren.</p>
	</div>
</div>
<!-- END Pavé légal NL -->
<div class="empty col1"></div>

<spacer :h="1" class="col4"></spacer>

<div class="empty col1"></div>
<div class="col2">
	<!-- Custom Checkbox Optin -->
	<custom-checkbox
	fieldname="optin"
	inputid="optin"
	:helper="$t('inputLabel.optin.helper')"
	v-model="fieldsValues.optin"
	special-style="vcheck"
	:idLead="lead.id"
	class="col2">
	<template v-slot:msg>{{$t('inputLabel.optin.label')}}</template>
</custom-checkbox>
<span v-html="$t('inputLabel.optin.complement')"></span>
</div>


</template>


<!-- Nav bar -->
<template v-slot:nav>
	<spacer :h="2" class="col4"></spacer>
	<div style="text-align:center;">
		<button @click="skipSurvey()" :disabled="!isClaimCashbackButtonActive">{{$t('navigation.claimCashback')}}</button>
	</div>
</template>

</page-form>















<page-form :title="$t('pageTitles.alreadySend')" :grid="true" v-else-if="currentPage==='participated'" key="participated">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.alreadySend')}}</p>
		</div>

	</template>
</page-form>

















<page-form :title="$t('pageTitles.beforeStart')" :grid="true" v-else-if="currentPage==='beforeStart'" key="beforeStart">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.beforeStart.text')}} {{actionStartDate}}.</p>
		</div>

	</template>
</page-form>






<page-form :title="$t('pageTitles.actionEnded')" :grid="true" v-else-if="currentPage==='actionEnded'" key="actionEnded">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.actionEnded.text')}}</p>
		</div>
<!--
		<div class="centeredContent col4">
			<a v-if="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_LU'" class="btn-link" id="discoverlink" href="https://www.purina.be/fr/marques/adventuros">{{$t('navigation.toCustomerWebsite')}}</a>
			<a v-else class="btn-link" id="discoverlink" href="https://www.purina.be/nl/merken/adventuros">{{$t('navigation.toCustomerWebsite')}}</a>
		</div>
	-->
</template>
</page-form>
















<page-form :title="$t('pageLitteracy.confirmation.title')" :grid="true" v-else-if="currentPage==='confirmation'" key="confirmation">
	<template v-slot:content>

		<breadcrumbs :steps="['registration','store','form','cashback']" :currentStep="4" class="col4"></breadcrumbs>

		<div class="col4 simpletext">
			<p v-html="$t('pageLitteracy.confirmation.textes.p1')"></p>
			<p>{{$t('pageLitteracy.confirmation.textes.p2')}}</p>

			<p v-if="!skipButtonIsClicked && surveyMustBeFilled" v-html="$t('pageLitteracy.confirmation.textes.coupon')"></p>

			<p>{{$t('pageLitteracy.confirmation.textes.p3')}}</p>
			<p>
				<em v-html="$t('pageLitteracy.confirmation.textes.p4')"></em>
			</p>
		</div>

	</template>
</page-form>
















</transition>





<div id="cheatnav" class="debug" v-if="isDevNavVisible && isAdminNavAllowed" :class="{'retracted':cheatPanelHidden}">
	<div class="swiper" @click="toggleCheatPanel">
		<span v-if="cheatPanelHidden">&Lang;</span>
		<span v-if="!cheatPanelHidden">&Rang;</span>
	</div>

	<div>
		<h5>NAVIGATION: <strong>{{currentPage}}</strong></h5>
		Mandatory survey: <strong>{{surveyMustBeFilled}}</strong>
	</div>
	<ul>
		<li @click="goto('beforeStart')">Before Action</li>
		<li @click="goto('inscription')">Inscription</li>
		<li @click="goto('confirminscription')">Registration confirm.</li>
		<li @click="goto('form1')">Form Page 1</li>
		<li @click="goto('confirmation')">Participation confirm.</li>
		<li @click="goto('maxreached')">Max participation</li>
		<li @click="goto('wrongcode')">Wrong code</li>
		<li @click="goto('participated')">Participation already sent</li>
		<li @click="goto('actionEnded')">After Action</li>
		<li @click="goto('maintenance')">Maintenance</li>
	</ul>
	<button style="width:  100%; " @click="isThereMessage=true; messageTitle='Error on demand'; messageContent=['You wanted it, you have it!']; messageType='alert';">Error on demand</button>
	<button style="width:  100%; " @click="isThereMessage=true; messageTitle='Info on demand'; messageContent=['This information is free!']; messageType='info';">Info on demand</button>
</div>




</div>
</template>



















<!-- ================================================================================== -->

<script>

	import PageForm from '@/components/PageForm.vue';
	import NavButtons from '@/components/NavButtons.vue';
	import ClaimBlocs from '@/components/ClaimBlocs.vue';
	import Headings from '@/components/Headings.vue';
	import CustomInput from "@/components/CustomInput";
	import CustomCheckbox from '@/components/CustomCheckbox.vue';
	// import CustomSelect from '@/components/CustomSelect.vue';
	// import MetaInput from "@/components/MetaInput";
	import FreeInput from "@/components/FreeInput";
	// import MetaCustomSelect from '@/components/MetaCustomSelect.vue';
	// import Popitem from '@/components/Popitem.vue';
	// import CustomRadio from '@/components/CustomRadio.vue';
	import Spacer from '@/components/Spacer.vue';
	import Breadcrumbs from '@/components/Breadcrumbs.vue';
	import Upload from "@/components/Upload";
	import PhoneInput from "../components/PhoneInput";
	import 'intl-tel-input/build/css/intlTelInput.css';
	// import SurveyCustomSelect from '@/components/SurveyCustomSelect.vue';
	import pixel from "../mixins/pixel";
	import Toaster from "@/components/Toaster";
	import Functions from "../mixins/Functions";
	import Cheat from "../mixins/Cheat";
	import config from "../config";

	export default {
		name:"Form",
		mixins: [pixel, Cheat, Functions],
		data () {
			return {

				isAdminNavAllowed : config.isAdminNavAllowed === 'true',
				surveyMustBeFilled : false,
				skipButtonIsClicked: false,
				isClaimCashbackButtonActive: false,
				isSurveyClaimCashbackButtonActive: false,

				ownerData: {},
				reparticipation: false,

				dateMessage: null,

				smsHelper: '', // used as modelValue. Maybe change name. …later.


				/* ! CHECK HERE IF FIELDS ARE REQUIRED OR NOT */
				fieldsValidity: {
					'form1': {
						// title: false,
						firstName: false,
						lastName: false,
						email: false,
						IBAN: false,
						purchaseDate: false,
						idLeadTMD1: false,
						idLeadTMD2: false,
						file: false,
						conditions: false,
					},
					'form2': {},
					'survey': {},
				},

				fieldsValues : {
					bic:null,
					iban:null,
					idtitle: null,
					idGender: null,
					uniqueCode : null,
					firstname: null,
					lastname: null,
					email: null,
					popitem: null,
					popitem2: {
						code: null,
						description: null
					},

					retailer: null,
					freeRetailer:null,
					purchaseDate: null,
					language: null,
					birthdate : null,
					phone1: null,
					phone2: null,
					adress: {
						adr1 : null,
						adr2 : null,
						number : null,
						box : null,
						country : 1,
						postalcode : null,
						town : null
					},
					societe1 : null,
					societe2 : null,
					vatCountry: null,
					vatCode : null,
					confidentiality :null,
					cg : null,
					optin: 0, // default value needed
					survey:{
						answersSQ1: null,
						answersSQ1alt: null,
					}
				}
			};
		},

		computed: {

		},

		components: {
			NavButtons,
			ClaimBlocs,
			Headings,
			Breadcrumbs,
			CustomInput,
			// CustomSelect,
			CustomCheckbox,
			// MetaCustomSelect,
			FreeInput,
			Spacer,
			Upload,
			PageForm,
			PhoneInput,
			Toaster,
			// Popitem,
		},

		methods: {

			/* ACTIONS TAKEN IF I GET THE LEAD FROM THE API */
			whatIfIGetTheLead(data){
				console.log(data);
				this.lead = data;
				/* getting the date in an object */
				this.actionFullDates = this.getFullDates();
				/* display some info in the console */
				this.displayAvailableParticipations();
				this.displayDatesInConsole();

				/* Check if it is not too soon */
				if (this.isTooSoon()){
					this.goto('beforeStart');
				}else if(this.isTooLate()){
					this.goto('actionEnded');
				}else{
					this.identifyOwnerByKey();
				}
				

			},

			/* ACTIONS TAKEN IF I DON'T GET THE LEAD FROM THE API */
			whatIfIDontGetTheLead(error){
				console.log(error.response?.data?.errors);
				/* XXXX To add : use the TOASTER to display the error */
			},


			identifyOwnerByKey () {
				this.keyFromUrl = this.getKeyFromUrl();
				this.checkKey();
			},



			skipSurvey(){
				this.surveyMustBeFilled = false;
				this.skipButtonIsClicked = true;
				this.sendParticipation();
			},

			setSurvey(val){
				this.surveyMustBeFilled = val;
			},






			/* Is it used ? I don't think so. But I should test. */
			reload(){
				this.alertDev("This function is not obsolete: please remove the comment");
				console.clear();
				this.goto('inscription');
				this.checkIfMaxParticipation();
			},









			/* CHECK IF FIELDS OF ONE PAGE HAVE BEEN VALIDATED */
			/* Returns true or false */
			isAllFieldsValidOnPage(page='form1'){

				/* Attention: if the page name does not exist (ie "Form1" instead of "form1") the value returned is true */
				/* Non-blocking user if I made a mistake, but non-blocking user nevertheless */
				/* Maybe I can check the name */

				let flag = true;
				for(let item in this.fieldsValidity[page]){
					if(this.fieldsValidity[page][item] === false){
						flag = false;
					}
				}
				return flag;
			},
			/* THIS ONE HAS ALMOST THE SAME NAME :( => NEED TO CLARIFY ) */
			areFieldsFilledOnPage(page){
				let allRequiredFieldsAreValid = true;
				/* Maybe I could use filter() or reduce() here ? */
				for (let item in this.fieldsValidity[page]){
					this.consoleInColor(item, this.fieldsValidity[page][item]);
					if (this.fieldsValidity[page][item]===false){
						allRequiredFieldsAreValid = false;
					}
				}
				return allRequiredFieldsAreValid;
			},











			/* Buttons are unlocked when all field on their page has been validated */
			checkIfMustUnlockClaimCashbackButton(val){
				console.log(`One input is sending ${val.valid} for field ${val.fieldname}.`);
				/* To store the validity in the data */
				this.updateValidityOfAField(val);
				/* To display the info in the console */
				this.consoleFieldsValidityOnCurrentPage();
				/* To unlock buttons if necessary */
			// XXXX WHAT FOR OTHER PAGES ?
			if(this.isAllFieldsValidOnPage('form1')){
				console.log(`This is ${this.isAllFieldsValidOnPage('form1')} so I unlock, isn't it?`);
				this.unlockClaimCashbackButton();
			}else{
				this.lockClaimCashbackButton();
			}
		},






		/* This function only displays info in the console */
		consoleFieldsValidityOnCurrentPage(){
			console.groupCollapsed(`Are all Fields of ${this.currentPage} on TRUE?`);
			this.fieldsValidity['form1'].conditions = Boolean(this.fieldsValues.cg); /* special checkbox case */
			let allRequiredFieldsAreValid = true;

			/* could this be simplified by .filter() or .some() ? */
			for (let item in this.fieldsValidity[this.currentPage]){
				this.consoleInColor(item, this.fieldsValidity[this.currentPage][item]);
				if (this.fieldsValidity[this.currentPage][item]===false){
					allRequiredFieldsAreValid = false;
				}
			}

			console.log("----------------------");
			this.consoleInColor("Acces granted: ", allRequiredFieldsAreValid);
			console.groupEnd();
		},












		/* ---- LOCK and UNLOCK Buttons ---- */
		/* Could probably be rewritten */
		/* Instead of using a specific function, use the currentPage var to target buttonOfPage(currentPage)? */
		deactiveAllButtons(){
			console.log("Deactive buttons");
			this.lockClaimCashbackButton();
			this.lockSurveyClaimCashbackButton();
			console.log("Buttons are deactivated");
		},
		activeAllButtons(){
			console.log("Active buttons");
			this.unlockClaimCashbackButton();
			this.unlockSurveyClaimCashbackButton();
		},
		lockClaimCashbackButton(){
			console.log("Claim Cashback button (on form) is locked");
			this.isClaimCashbackButtonActive = false;
		},
		unlockClaimCashbackButton(){
			console.log("Claim Cashback button (on form) is unlocked");
			this.isClaimCashbackButtonActive = true;
		},
		lockSurveyClaimCashbackButton(){
			console.log("Claim Cashback button (on survey) is locked");
			this.isSurveyClaimCashbackButtonActive = false;
		},
		unlockSurveyClaimCashbackButton(){
			console.log("Claim Cashback button (on survey) is unlocked");
			this.isSurveyClaimCashbackButtonActive = true;
		},









		checkIfalreadyParticipated(){
			/* check if already participated (status === 30) or if reparticipation var is true */
			if (this.ownerData.status !== "30" || this.reparticipation){
				this.noParticipationOrReparticipation();
			}else{
				this.participationAlreadySent();
			}
		},





		noParticipationOrReparticipation(){
			console.log("Participation has not been sent yet or this is a reparticipation.");
			console.log((this.reparticipation)?"(This is a reparticipation)":"(This is not a reparticipation)");
			this.goto('form1');
		},

		participationAlreadySent(){
			console.log("Participation already sent and this is not a reparticipation.");
			this.goto('participated');
		},











		/* ------------------------------ */

		putDataInFields(){
			console.group("Putting data into fields");
			if(this.reparticipation){
				console.log("This is a reparticipation");
				this.putDataIntoFieldsForAReparticipation();
			} else {
				console.log("This is no reparticipation");
				this.IfMailPutMailInMailFieldAndDisable();
				this.ifPhonePutPhoneInPhoneFieldAndDisable();
			}
			console.groupEnd();
		},


		IfMailPutMailInMailFieldAndDisable(){
			if(this.ownerData.email){
				this.fieldsValues.email = this.ownerData.email;
				this.isMailFieldDisabled = true;
			}
		},

		ifPhonePutPhoneInPhoneFieldAndDisable(){
			if(this.ownerData.phonenumber1){
				this.fieldsValues.phone1 = this.ownerData.phonenumber1;
				this.isPhoneFieldDisabled = true;
			}
		},









		/* WHEN REPARTICIPATION, METHODS TO PUT SPECIFIC DATA INTO FIELDS */

		treatParticipationMetaData(category){
			/* ===== WIP 1071 ===== */
			/* la première reçue, avec idleadtmdv === 1 correspond à l'enquête, obligatoire ou non */
			/* La seconde seulement est une data utilisateur */
			this.bigOwnerData[category].forEach((item, index) => {
				if(item.idleadtmd !== 1){
					console.log(item);
					this.fieldsValues.survey[`answersSQ${index}`] = item.idleadtmdv;
					console.log(this.fieldsValues.survey);
				}
			});
			/* ===== /WIP 1071 ===== */
		},

		treatProofOfPurchaseHederConsumer(category){
			console.group("treatProofOfPurchaseHederConsumer");
			/* .freeretailername // "TEST Retailer" */
			console.log(this.bigOwnerData[category].freeretailername);
			this.fieldsValues.freeRetailer = this.bigOwnerData[category].freeretailername;
			/* .purchasedate // 2022-01-06 */
			console.log(this.bigOwnerData[category].purchasedate);
			this.fieldsValues.purchaseDate = this.bigOwnerData[category].purchasedate;
			console.groupEnd();
		},

		treatParticipationOwnerData(category){
			let keys = Object.keys(this.bigOwnerData[category]);
			console.log(keys);
			if(typeof this.bigOwnerData[category] === 'object'){
				keys.forEach((key) => {
					console.log(this.bigOwnerData[category][key]);
					this.fieldsValues[key] = this.bigOwnerData[category][key];
				});
				console.log(this.fieldsValues);
			}
		},



		putDataIntoFieldsForAReparticipation(){
			let categories = Object.keys(this.bigOwnerData);
			categories.forEach((category) => {

				console.log("Reading " + category);

				if(category === 'ParticipationMetaData'){
					console.log("Treating " + category);
					this.treatParticipationMetaData(category);
				}

				if(category === "ProofOfPurchaseHeaderConsumer"){
					console.log("Treating " + category);
					this.treatProofOfPurchaseHederConsumer(category);
				}

				if(category === 'ParticipationOwner'){
					console.log("Treating " + category);
					this.treatParticipationOwnerData(category);
				}

				if (category === "Participation"){
					console.log("Nothing to do (yet) inside " + category);
				}

			});
		},






		getLocalizingData(idlead){
			console.log("Getting localizing data");
			this.axios.get(
				`/dropdown/${idlead}/languages-code`
				)
			.then(res => {
				console.log("Localizing data found");
				this.localizingDataFound(res);
			})
			.catch(error => {
				console.error("Localizing data not found");
				this.localizingDataNotFound(error);
			});
		},



		localizingDataFound(res){
			/* TODO - This one will be used to display switch lang buttons */
			/* TODO - It can be used to get the correspondance between locale and id to be sent */

			console.groupCollapsed("WIP - WIP - WIP - WIP")
			console.log(res.data);
			// I receive an array with an entry for each case
			for (let item in res.data){
				console.log(res.data[item].countrycodereference.code);
				console.log(res.data[item].countrycodereference.id);
				console.log(res.data[item].languagecodereference.code);
				console.log(res.data[item].languagecodereference.id);
			}

			let temp = this.getIdOfLanguage();
			console.log("Je renvoie " + temp + " comme id de lang");
			console.groupEnd();
		},

		getIdOfCountry(){
			/* Must get the info from a dropdown, or hardcoded */
			/* Here, on U-cran, there is only BE, so id = 1 */
			return 1;
		},

		getIdOfLanguage(){
			/* Must return the id based on the currentLocale */
			let defaultIdLang = 2;
			let idLang = defaultIdLang;
			let currentLocale = this.$i18n.locale.slice(0,2).toUpperCase();
			/* this must be replaced by a comparaison with data received from API => See getLocalizingData() */
			if (currentLocale === 'FR'){
				idLang = 1;
			}else{
				idLang = defaultIdLang;
			}
			/* END */
			return idLang;
		},

		localizingDataNotFound(error){
			console.log(error);
		},




		/* ------------------------------ */

		getOwnerData(){
			console.group("Getting Owner Data for key " + this.keyFromUrl);
			this.axios.get(
				`/participationOwner/${this.keyFromUrl}`
				)
			.then(res => {
				console.log(res.data);
				console.log("I managed to get an answer for the owner ID: " + res.data?.ParticipationOwner?.id);
				this.whatIfIGetTheOwnerData(res);
			})
			.catch(error => {
				console.error("I can't get the owner data");
				this.whatIfIDontGetTheOwnerData(error);
			})
			.then(()=>{
				console.groupEnd();
			});
		},


		whatIfIDontGetTheOwnerData(error){
			console.log(error);
			console.log(error?.response);
			console.log(error?.response?.data);
			console.log(error?.response?.data?.message);
			this.goto('wrongcode');
			/* Display error for user */
			let titleError = `${error.response.statusText} (${error.response.status})`;
			this.displayMessage(titleError, [error.response.data.message], 'alert');
		},

		whatIfIGetTheOwnerData(res){
			this.setOwnerData(res);
			this.displayOwnerDataInConsole();
			this.setLang(this.ownerData.idlanguagecode);
			/* We got user data. Does the user is in right time for his (re)participation? */ 
			this.checkIfInDate();	
		},


		setOwnerData(res){
			if(res.data.reparticipation) {
				this.ownerData = res.data["ParticipationOwner"];
				this.bigOwnerData = res.data;
				console.log("=== I found this IS a reparticipation");
				this.reparticipation = true;
			} else {
				console.log("=== I found this is NOT a reparticipation");
				this.ownerData = res.data;
			}
		},

		displayOwnerDataInConsole(){
			console.log("===============================");
			console.groupCollapsed("ownersData : participation, reparticipation")
			console.log(this.ownerData);
			console.log(this.bigOwnerData);
			console.groupEnd();
			console.log("===============================");
		},


		setLang (id) {
			/* Updates i18n and store */
			let langs = ['en', 'fr', 'nl'];
			console.log("::: Change lang to: "+ langs[id] + " :::");
			if (id == '1'){
				this.$i18n.locale = 'fr_BE';
			}else{
				this.$i18n.locale = 'nl_BE';
			}
			this.$store.dispatch('setIdLanguageCode', id);
		},


		putContactInForm(){
			console.log("Put phone|mail in place.");
			if(this.ownerData.phonenumber1){
				console.log("—> set phone number");
				this.setPhone(this.ownerData.phonenumber1);
			}
			if(this.ownerData.email){
				console.log("—> set email adress");
				this.setMail(this.ownerData.email);
			}
		},


		composeData(){
			let data = {};
			/* prep of the DATA before sending participation */

			data = {
				"Authorization" : this.oauth.token_type + ' ' + this.oauth.access_token,
				"idLeadManagement": this.lead.id,
				"ParticipationOwner": {
					"idParticipationOwner": this.ownerData.id,
					"idtitle": this.fieldsValues.idtitle,
					"idGender": 3, /* As I don't collect gender, what could I give here? */
					"phoneNumber1": this.fieldsValues.phone1,
					"lastName": this.fieldsValues.lastname,
					"firstName": this.fieldsValues.firstname,
					"email": this.fieldsValues.email,
					"IBAN": this.fieldsValues.iban,
					"optin": this.fieldsValues.optin,
					"generalCondition": this.fieldsValues.cg,
					// "countryCode": this.fieldsValues.adress.country,
					// "ISOLanguageCode":  this.$i18n.locale.slice(0,2),
					"idLanguageCode": this.getIdOfLanguage(),
					"idCountryCode": this.getIdOfCountry(),
				},
				"Participation": {
					"idParticipationType": 1,
					'fingerprint': 'v2.cfAGmgqRuc1r8C3rr3GK.vfPuVjq0wC3BMTlg_606174c1705d61.27931079'
				},
				
				"ProofOfPurchaseDetailConsumer": [
				{"itemCode": this.fieldsValues.cnk1, "quantity": 1},
				{"itemCode": this.fieldsValues.cnk2, "quantity": 1},
				],
				
				"ProofOfPurchaseHeaderConsumer": {
					"idPOPRetailer": this.fieldsValues.retailer,
					"freeRetailerName" : this.fieldsValues.freeRetailer,
					"purchaseDate" : this.fieldsValues.purchaseDate,
				},
				"ParticipationMetaData": [
				/*
				{
					"idLeadTMD": 1,
					"idLeadTMDV": this.surveyMustBeFilled ? 1 : 2
				}
				// */
				]
			};










			/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
			/* META QUESTIONS ==== HOW TO AUTOMATE THIS ? */
			if(this.fieldsValues.survey.answersSQ1 !== '' && this.fieldsValues.survey.answersSQ1 !== null) {
				/* dropdown */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question1,
					"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ1)
				});
			}
			if(this.fieldsValues.survey.answersSQ1alt !== '' && this.fieldsValues.survey.answersSQ1alt !== null) {
				/* Input free text */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question1,
					"idLeadTMDV" : '',
					"answer": this.fieldsValues.survey.answersSQ1alt
				});
			}
			/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

			return data;
		},







		areConditionsToSendTheParticipationMet(){
			console.group("Check sending conditions");
			let conditionsAreMet = true;

			/* XXXX ADD HERE MANUALLY OR WRITE CODE TO LOOP ON LIST OF PAGES */

			if(!this.areFieldsFilledOnPage('form1')){
				conditionsAreMet = false;
			}

			if (this.surveyMustBeFilled){
				if(!this.areFieldsFilledOnPage('survey')){
					conditionsAreMet = false;
				}
			}

			console.log("Sending conditions: ", conditionsAreMet);
			console.groupEnd();
			return conditionsAreMet;
		},




		resetButtons(){
			/* Default state: false */
			this.isClaimCashbackButtonActive = false;
			this.isSurveyClaimCashbackButtonActive = false;
			/* Must be reinitialized, to avoid blocking user on choicesurvey page */
			this.skipButtonIsClicked = false;
		},




		sendParticipation(){
			/* Deactive button and sending form => Only if field e-mail OR phone is validated ! */
			/* do something only when light is green, to avoid sending participation without something, in case of cheat. */
			this.deactiveAllButtons();
			if(this.areConditionsToSendTheParticipationMet()){
				this.conditionsForParticipationAreMet();
			}else{
				this.conditionsForParticipationAreNotMet();
			}
		},




		conditionsForParticipationAreMet(){
			let data = this.composeData();
			console.log("Before posting, what did I received from composeData() ?");
			console.log(data);
			/* send fieldsvalues to API */
			this.axios.post(
				`/participation`,
				data
				)
			.then(res => {
				console.log("I managed to POST the participation! ");
				this.participationSuccessful(res);
			})
			.catch(error => {
				console.log("An error has occured: ");
				this.participationFailed(error);
			});
		},


		conditionsForParticipationAreNotMet(){
			this.activeAllButtons();
			let message = this.$t('errors.fieldMissingValue');
			this.anErrorOccured({'message':message});
			this.redirectToForm();
		},




		redirectToForm(){
			/* redirection to first page */
			this.goto('form1');
			/* reactivate buttons to allow re-submission */
			this.isClaimCashbackButtonActive = true;
		},




		participationSuccessful(res){
			console.log(res);
			this.goto('confirmation');
		},
		participationFailed(error){
			this.anErrorOccured(error);
			this.redirectToForm();
		},




		anErrorOccured(error){
			console.group("ERROR");
			console.log(error);
			/* if "notifications" key is present */

			if(error?.response?.data?.notifications?.length > 0){
				this.displayMessage('Error', error.response.data.notifications, 'alert');
			}else if(error?.response?.data?.message?.length > 0){
				/* if "message" exist, displaying it instead */
				this.displayMessage('Error', [error.response.data.message], 'alert');
			}else{
				/* just the message (in case of error 500) */
				this.displayMessage('Error', [error.message], 'alert');
			}
			console.groupEnd();
		},

		checkMaintenance() {
			console.log('==== Maintenance Check ====')
			let status = this.$store.getters.getMaintenance;
			console.log(status);
			if(status === true) {
				this.currentPage = 'maintenance';
			}else{
				this.startAction();
			}
		},

		startAction() {
			this.getLocalizingData(this.idLead);
			this.getLead(this.idLead);
		},

	},

	beforeMount(){
		console.clear();
		/* ALL starts here, by this little function */
		this.checkMaintenance();
	},
	mounted(){
	},
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .2s, height .2s ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		height:0;
	}
</style>
