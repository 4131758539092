export default {
    apiUrl: process.env.VUE_APP_BACKENDURL,
    idlead: process.env.VUE_APP_IDLEAD,
    assetPath: process.env.VUE_APP_ASSETSPATH,
    whatsappNumber: process.env.VUE_APP_WHATSAPPNUMBER,
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    allowMaintenanceCheck: process.env.VUE_APP_ALLOWMAINTENANCECHECK,
    isAdminNavAllowed: process.env.VUE_APP_ISADMINNAVALLOWED,

    form: {
        whatsappCode: "Clearblue",
    },

    enquete: {
        // 801 / ???
        question1: process.env.VUE_APP_QUESTION1,
        // 802 / ???
        question2: process.env.VUE_APP_QUESTION2,
        // 999: Lorem ipsum
        // question3: process.env.VUE_APP_QUESTION3,

    }
}
