<template>
	<div class="claimblocs">

		<slot name="title"></slot>

		<template v-for="(bloc, index) in blocType" :key="index">

			<div class="claimor" v-if="blocType.length >= 2 && index !== 0">{{$t('pageLitteracy.registration.or')}}</div>

			<div class="claimbloc">

				<div v-if="bloc==='email'">
					<custom-input
					fieldname="email"
					:placeholder="$t('inputLabel.email.placeholder')"
					inputType="text"
					:idlead="config.idlead"
					:label="$t('inputLabel.email.label')"
					:helper="$t('inputLabel.email.helper')"
					:isRequired="false"
					v-model.lazy:modelValue="email"
					ref="email"
					></custom-input>

					<button @click="askCreationParticipation('email');" :disabled="isLoading">{{$t('pageLitteracy.registration.claimMail')}}</button>
				</div>

				<div v-if="bloc==='whatsapp'">
					<label for="nothing">{{$t('pageLitteracy.registration.whatsappTitle')}}</label>
					<p style="max-width:80%; margin: 0 auto 2em;">{{$t('pageLitteracy.registration.whatsappInfo')}}</p>
					<!-- Following current locale, send code xxxx-fr or xxxx-nl -->
					<a :href="'https://wa.me/' + config.whatsappNumber + '?text=' + config.form.whatsappCode + '-' + lang.toUpperCase()" class="btn-link" target="_blank" @click.capture="askCreationParticipation('whatsapp');" :class="{disabled:isLoading}">
						<i class="fab fa-whatsapp"></i> {{$t('pageLitteracy.registration.claimWhatsapp')}} <i class="fab fa-whatsapp"></i>
					</a>
				</div>

				<div v-if="bloc==='sms'">
					<phone-input
					fieldname="phoneNumber1"
					v-model="smsHelper"
					inputType="phone"
					:idlead="config.idlead"
					:placeholder="$t('inputLabel.phone.placeholder')"
					:label="$t('inputLabel.phone.label')"
					:helper="$t('inputLabel.phone.helper')"
					:isRequired="false"
					@internationalNumber="setPhone"
					ref="phoneNumber1"
					></phone-input>

					<button @click="askCreationParticipation('phoneNumber1');" :disabled="isLoading">{{$t('pageLitteracy.registration.claimSms')}}</button>

				</div>

			</div>
		</template>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import CustomInput from "@/components/CustomInput";
	import config from "../config";
	import PhoneInput from "../components/PhoneInput";
	import 'intl-tel-input/build/css/intlTelInput.css';

	export default {
		name:"ClaimBlocs",

		components: {
			CustomInput,
			PhoneInput,
		},

		props: {
			blocType : {
				type: [Array]
			},
			lang : {
				type: String,
				required: false
			}

		},
		data(){
			return {
				email : '',
				smsHelper: '',
				sms: '',
				whatsapp: '',
				choosenMean : '',
				isLoading : false,
				config: config,

				isThereMessage: false,
				messageContent: [],
				messageType: "alert",
			}
		},

		methods : {
			setPhone(number) {
				console.log(number);
				this.sms = number;
			},
			
			leaveThisPage(){
				console.log("I leave the inscription page");
				this.$emit('goto', 'confirminscription');
			},

			check(fieldname){
				let el = document.getElementById(fieldname);
				if(el.classList.contains('hasSuccess')){
					console.log("Le champ " + fieldname + " a la bonne classe");
					return true;
				}else{
					console.log("Le champ est vide ou en erreur");
					this.$refs[fieldname].validateMyself();
					return false;
				}
			},

			askCreationParticipation(fieldname) {
				/* changing the button status */
				this.isLoading = true;

				console.group("CreateParticipationOwner");
				console.log("I asked to create my participation ownership");
				if(fieldname === "phoneNumber1" && this.check(fieldname)){
					console.log("…by phone");
					this.axiosPart(this.sms, fieldname);
					this.choosenMean = 'phone';
				}
				else if(fieldname === "email" && this.check(fieldname)){
					console.log("…by email");
					this.axiosPart(this.email, fieldname);
					this.choosenMean = 'email';
				}
				else if(fieldname === "whatsapp"){
					console.log("…by whatsapp");
					this.choosenMean = 'whatsapp';
					this.leaveThisPage();
				}else{
					this.isLoading = false;
				}
				// HERE, each field has been checked, if needed an error message is displayed. Nothing else happens.
			},


			getIdLanguage(){
				let id = 2;
				if (this.$i18n.locale.slice(0,2).toUpperCase() === 'FR'){
					id = 1;
				}else if (this.$i18n.locale.slice(0,2).toUpperCase() === 'NL'){
					id = 2;
				}
				console.log("Claimblock determine that idLanguage is " + id);
				return id;
			},


			gatherData(wayToContact,fieldname){
				return {
					"idLeadManagement": config.idlead,
					"isolanguagecode": this.$i18n.locale.slice(0,2),
					[fieldname] : wayToContact,
					"idLanguageCode" : this.getIdLanguage(),
				};
			},


			axiosPart(wayToContact, fieldname) {
				// set the loading state for button (to be created)
				this.isLoading = true;

				let data = this.gatherData(wayToContact,fieldname);
				console.group("Data collected for registration:"); console.log(data); console.groupEnd();

				this.axios.post(
					`/participationOwner`,
					data
					)
				.then(res => {
					console.log("I managed to create the participation! ");
					this.participationOwnerCreated(res);
				})
				.catch((error) => {
					console.error("I'm sorry Dave, I'm afraid I can't do that");
					this.participationOwnerReportError(error);
				});
			},

			participationOwnerCreated(res){
				console.log(res);
				console.groupEnd();
				this.$emit('mean', this.choosenMean);
				this.leaveThisPage();
			},

			participationOwnerReportError(error){
				console.log(error.response.data);
				console.log("------");
				console.log(error.response.data.message);
				console.groupEnd();
				this.$emit('errorpresent', error);
				this.anErrorOccured(error.response.data);
				this.isLoading = false;
			},

			anErrorOccured(error){
				console.group("ERROR data:");
				console.log(error);
				let arrayMessage = [];
				/* if "notifications" key is present */

				if(error.notifications?.length > 0){
					console.log("There is notifications");
					this.displayMessage(error.notifications, 'alert');
				}else if(error.message?.length > 0){
					console.log("No notifications? Display message");
					/* if "message" exist, displaying it instead */
					arrayMessage.push(error.message);
					this.displayMessage(arrayMessage, 'alert');
				}else{
					/* just the message (in case of error 500) */
					console.log("Neither notifications nor messages?");
					arrayMessage.push(error);
					this.displayMessage(arrayMessage, 'alert');
				}
				console.groupEnd();
				/* reactivate buttons to allow re-submission */
				this.isClaimCashbackButtonActive = true;
			},


			displayMessage(message, mode) {
				console.log("Displaying message: " + message);
				this.isThereMessage = true;
				this.messageContent = message;
				this.messageType = mode;
			},

			hideMessage() {
				this.isThereMessage = false;
			},
		}
	}

</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
